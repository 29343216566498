/* =============================================
			Theme Reset Style					
============================================= */
@import url(./assets/css/icomoon.css);

:root {
    --Malibu: #5ab5df;
    --PaynesGrey: #3f414d;
    --transition: all 300ms ease-in-out;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:after,
*:before {
    transition: var(--transition);
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif; */
body {
    color: #666;
    background: #eff4fb !important;
    font: 400 14px/23px 'Poppins', Arial, Helvetica, sans-serif;
}

body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    margin: 0 0 8px;
    font-weight: 400;
    line-height: 1.5;
    font-style: normal;
    color: var(--PaynesGrey);
    text-transform: capitalize;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #0f0f0f;
}

p a {
    transition: var(--transition);
}

p a,
p a:hover {
    text-decoration: underline;
}

p {
    margin: 0 0 20px;
    line-height: 20px;
    letter-spacing: normal;
}

a {
    transition: var(--transition);
    text-decoration: none !important;
}

a:hover,
a:focus,
a:active {
    outline: none;
    text-decoration: none;
}

ul {
    margin: 0;
}

figure {
    margin-bottom: 6px;
    position: relative;
}

img {
    height: auto;
    max-width: 100%;
}

strong {
    color: #414141;
}

iframe {
    width: 100%;
    float: left;
    border: none;
}

button {
    border: none;
    cursor: pointer;
    background: none;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

textarea,
select,
.up-select select,
.form-control,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    color: #666;
    outline: none;
    height: 40px;
    background: #fff;
    font-size: 14px;
    -webkit-box-shadow: none;
    box-shadow: none;
    line-height: 18px;
    padding: 10px 20px;
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #dbdbdb;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

textarea.form-control {
    resize: none;
    height: 210px;
}

.form-control::-webkit-input-placeholder {
    color: #666;
}

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
    color: #666;
}

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
    color: #666;
}

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
    color: #666;
}

/* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
    color: #666;
}

/* Microsoft Edge */
input:focus,
.select select:focus,
.form-control:focus {
    color: #666;
    border-color: #666;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.form-group {
    width: 100%;
    float: left;
    margin: 0 0 20px;
}

label {
    color: #666;
    display: block;
    font-weight: 400;
    margin-bottom: 10px;
}

form p {
    float: left;
    position: relative;
    width: 100%;
}

form p span i {
    top: 13px;
    left: 16px;
    color: #474747;
    position: absolute;
}

option {
    padding: 5px 15px;
}

li {
    line-height: 28px;
    list-style: disc inside none;
}

ul ul {
    margin-left: 20px;
}

ol {
    float: none;
    list-style: decimal;
    padding-left: 15px;
}

ol ol {
    margin-left: 20px;
}

ol li {
    list-style: decimal;
    width: 100%;
}

table {
    background-color: transparent;
    max-width: 100%;
}

th {
    text-align: left;
}

table {
    width: 100%;
    margin-bottom: 15px;
}

table>thead>tr>th,
table>tbody>tr>th,
table>tfoot>tr>th,
table>thead>tr>td,
table>tbody>tr>td,
table>tfoot>tr>td {
    border-top: 1px solid #dbdbdb;
    border: 1px solid #dbdbdb;
    line-height: 2.5;
    padding-left: 3px;
    text-align: center;
    vertical-align: top;
}

table thead tr th {
    border-top: 1px solid #dbdbdb;
    text-align: center;
    text-transform: capitalize;
}

.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>th,
.table>caption+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>td,
.table>thead:first-child>tr:first-child>td {
    border-top: 1px solid #dbdbdb;
}

table>thead>tr>th {
    border-bottom: 2px solid #dbdbdb;
    vertical-align: bottom;
}

table>caption+thead>tr:first-child>th,
table>colgroup+thead>tr:first-child>th,
table>thead:first-child>tr:first-child>th,
table>caption+thead>tr:first-child>td,
table>colgroup+thead>tr:first-child>td,
table>thead:first-child>tr:first-child>td {
    border-bottom: 0;
}

table>tbody+tbody {
    border-top: 2px solid #dbdbdb;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    padding: 6px 10px;
}

p ins {
    color: #666;
}

dl dd {
    margin-left: 20px;
}

address {
    font-style: italic;
}

/* =============================================
			Theme Custom Select					
============================================= */
.up-select {
    color: #666;
    float: left;
    width: 100%;
    position: relative;
    text-transform: uppercase;

    &:after {
        top: 0;
        right: 15px;
        z-index: 1;
        color: #666;
        display: block;
        content: '\ea48';
        line-height: 40px;
        position: absolute;
        text-align: center;
        font-size: inherit;
        pointer-events: none;
        font-family: 'icomoon';
    }

    select {
        z-index: 1;
        width: 100%;
        position: relative;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        option {
            color: #666;
        }
    }
}

/* =============================================
		Theme Custom CheckBox Radio				
============================================= */
.up-radio,
.up-radio label,
.up-checkbox,
.up-checkbox label {
    margin: 0;
    width: 100%;
    float: left;
    position: relative;
}

.up-radio label,
.up-checkbox label {
    cursor: pointer;
    display: block;
    padding: 0 0 0 20px;
}

.up-radio input[type=radio],
.up-checkbox input[type=checkbox] {
    display: none;
}

.up-radio input[type=radio]+label:before,
.up-checkbox input[type=checkbox]+label:before {
    top: 4px;
    left: 0;
    color: #373542;
    font-size: 14px;
    line-height: 14px;
    content: '\f096';
    position: absolute;
    font-family: 'FontAwesome';
}

.up-radio input[type=radio]+label:before {
    content: '';
}

.up-radio input[type=radio]:checked+label:before,
.up-checkbox input[type=checkbox]:checked+label:before {
    content: '\f046';
}

/* =============================================
			Theme Global Elements				
============================================= */
.up-haslayout {
    width: 100%;
    float: left;
}

.up-sectionspace {
    padding: 100px 0;
}

.up-btn-sm {
    padding: 0 20px;
    line-height: 30px;
}

.up-btn {
    color: #fff;
    padding: 0 25px;
    position: relative;
    text-align: center;
    border-radius: 8px;
    display: inline-block;
    vertical-align: middle;
    background: var(--Malibu);
    border: 1px solid #dbdbdb;
    font: 400 14px/40px 'Montserrat', Arial, Helvetica, sans-serif;

    &:hover {
        background: #3c93bb;
    }
}

.up-btn:hover {
    color: #fff;
}

.up-btn-lg {
    width: 100%;
}

.up-btnwithloader {
    display: flex;
    align-items: center;
    justify-content: center;

    .lds-dual-ring {
        width: 25px;
        height: 25px;
        margin: 0 0 0 5px;
        display: inline-flex;
        align-items: center;

        &:after {
            margin: 0;
            width: 20px;
            height: 20px;
            border-width: 3px;
            border-color: #fff transparent #fff transparent;
        }
    }
}

.up-description {
    width: 100%;
    float: left;
}

.up-bglight {
    background: #f7f7f7;
}

.up-bgdark {
    background: #373542;
}

.navbar-toggle {
    border: 0;
    margin: 3px 0;
    border-radius: 0;
    padding: 10px 12px;

    .icon-bar {
        background: #fff;
    }
}

.up-formtheme {
    width: 100%;
    float: left;

    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        position: relative;

        .form-group {
            width: 100%;
            float: left;
            margin: 0 0 25px;
            position: relative;

            label {
                width: 100%;
                float: left;
                margin: 0 0 20px;
                font-size: 14px;
                line-height: 17px;
                color: var(--PaynesGrey);
            }

            input {
                width: 100%;
                height: 45px;
                border-radius: 8px;
                border: 1px solid rgba(112, 112, 112, 0.25);
            }

            textarea {
                width: 100%;
                float: left;
                resize: none;
                height: 110px;
                border-radius: 8px;
            }

            .form-text {
                color: #ff0000 !important;
            }
        }
        .up-inputwidthfifty {
            width: 100%;
            float: left;
            margin: 0 0 16px;
        
            .form-group {
                margin: 0 8px 0 0;
                width: calc(50% - 8px);
        
                & + .form-group {
                    margin: 0 0 0 8px;
                }
            }
        }

        .up-btn {
            border-color: rgba(15, 21, 135, 0.24);
        }
    }
}

.up-inputwithicon {
    width: 100%;
    float: left;
    position: relative;

    i {
        top: 0;
        right: 0;
        z-index: 2;
        width: 40px;
        height: 40px;
        line-height: 40px;
        position: absolute;
        text-align: center;
    }
}

/* =============================================
			Theme BreadCrumb Style				
============================================= */
.up-breadcrumb {
    padding: 0;
    margin: 0;
    color: #fff;
    float: right;
    list-style: none;
    text-align: right;
    text-transform: uppercase;
    font: 14px/44px 'Montserrat', Arial, Helvetica, sans-serif;

    li {
        width: auto;
        list-style-type: none;
        display: inline-block;
        vertical-align: middle;
        line-height: inherit;
        padding: 0 15px;
        position: relative;

        &:after {
            top: 0;
            left: -3px;
            content: '-';
            position: absolute;
        }

        &:last-child {
            padding: 0 0 0 15px;
        }

        &:first-child:after {
            display: none;
        }

        a {
            display: block;
            color: #fff;
        }
    }
}

/* =============================================
			Theme Styling						
============================================= */
.up-wrapper {
    overflow: hidden;
    padding: 110px 115px 30px 100px;
    &.up-fullwidthcontent{
        padding-right: 30px !important;
        .up-header{
            width: 100% !important;
        }
    }
    &.up-wrapperthree{
        padding-left: 30px !important;
    }
}

.up-main {
    width: 100%;
    float: left;
}

/* =============================================
			Header Style					
============================================= */
.up-header {
    top: 0;
    left: 0;
    float: left;
    z-index: 10;
    position: fixed;
    background: #fff;
    width: calc(100% - 84px);
    -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
}

.up-headercontent {
    width: 100%;
    float: left;
    padding: 20px 0;
    text-align: center;
}

.up-menuiconholder {
    float: left;
    width: 70px;
    height: 44px;
    line-height: 44px;
    text-align: center;

    button {
        line-height: normal;
    }
}

.up-logo {
    float: left;
    width: 170px;
    padding: 3px 25px;

    a {
        display: block;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

.up-globalsearch {
    width: 100%;
    max-width: 530px;
    min-width: 530px;
    display: inline-block;
    vertical-align: middle;
}

.up-formglobalsearch {
    fieldset {
        .form-group {
            margin: 0;
            position: relative;

            input {
                border-radius: 50px;
                background: #f5f5f5;
                padding: 10px 50px 10px 20px;
                border: 1px solid rgba(112, 112, 112, 0.12);
            }

            .up-btnsearch {
                top: 50%;
                left: 3px;
                padding: 0;
                width: 38px;
                height: 35px;
                display: flex;
                font-size: 15px;
                line-height: 38px;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
                background: #0f1587;
                background: -moz-linear-gradient(45deg, #0f1587 0%, #5ab5df 100%);
                background: -webkit-linear-gradient(45deg, #0f1587 0%, #5ab5df 100%);
                background: linear-gradient(45deg, #0f1587 0%, #5ab5df 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f1587', endColorstr='#5ab5df', GradientType=1);
            }
        }
    }
}

.up-headerrightarea {
    float: right;
    padding: 0 40px 0 0;
}

.up-themedropdown {
    float: left;

    .dropdown-toggle {
        border: 0;
        padding: 0;
        background: none;

        &:after {
            display: none;
        }

        &:hover {
            background: none;
        }

        &:focus {
            outline: none !important;
            box-shadow: none !important;
            background: none !important;
        }
    }
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
    border: 0 !important;
    outline: none !important;
    background: none !important;
    box-shadow: none !important;
}

.up-userimage {
    float: left;
    width: 46px;
    height: 46px;
    margin: 0 20px 0 0;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-userprofilecontent {
    float: left;
    position: relative;
    width: calc(100% - 66px);
    padding: 15px 24px 15px 0;

    h3 {
        margin: 0;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        font-family: 'Montserrat', sans-serif;
    }

    i {
        top: 50%;
        right: 0;
        position: absolute;
        color: var(--PaynesGrey);
        -webkit-transform: rotate(90deg) translateX(-200%);
        -moz-transform: rotate(90deg) translateX(-200%);
        -ms-transform: rotate(90deg) translateX(-200%);
        -o-transform: rotate(90deg) translateX(-200%);
        transform: rotate(90deg) translateX(-200%);
    }
}

.up-userimgtag {
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    background: #fff;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--Malibu);

    img {
        width: auto;
        height: auto;
    }
}

.up-themedropdownmenu {
    border: 0 !important;
    top: 75px !important;
    width: auto !important;
    padding: 20px !important;
    font-size: 14px !important;
    min-width: auto !important;
    transform: none !important;
    border-radius: 12px !important;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);

    &:after {
        top: -6px;
        left: 45px;
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 4px;
        background: #fff;
        position: absolute;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    a {
        color: #707070;
        font-weight: 400;
        padding: 15px 0 !important;
        font-family: 'Montserrat', sans-serif;
        border-bottom: 1px solid rgba(112, 112, 112, 0.24);

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        &:hover,
        &:focus,
        &:active {
            color: #707070;
            background: none;
        }

        &:hover {
            color: var(--Malibu);
        }

        span {
            display: block;
            position: relative;
            padding: 0 0 0 20px;

            &:before {
                left: 0;
                top: 50%;
                width: 6px;
                content: '';
                height: 6px;
                border-radius: 50%;
                position: absolute;
                background: #707070;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }
}

.up-messgnotificationicons {
    margin: 0;
    float: left;
    list-style: none;
    padding: 11px 35px 11px 0;

    li {
        float: left;
        padding: 0 10px;
        line-height: normal;
        list-style-type: none;

        button {
            display: block;
        }

        .up-themedropdownmenu {
            padding: 0 !important;
            left: auto !important;
            width: 325px !important;
            right: -50px !important;

            &:after {
                left: auto;
                right: 50px;
            }
        }
    }
}

.up-messagesnotihead {
    width: 100%;
    float: left;
    padding: 20px;
    background: #fff;
    border-radius: 12px !important;

    h3 {
        margin: 0;
        float: left;
        color: #000;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-btnmarkasread {
    float: right;
    font-size: 12px;
    color: #707070;
    font-weight: 500;
    line-height: 14px;
    font-family: 'Montserrat', sans-serif;
}

.up-messagesnotificationsdropdown {
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    height: 300px;
    overflow: auto;
    list-style: none;

    li {
        width: 100%;
        float: left;
        list-style-type: none;
        padding: 25px 15px 25px 12px;
    }
}

.up-messagesnotificationsdropdown::-webkit-scrollbar {
    display: none;
}

.up-messagesnotification {
    float: left;
    width: 100%;

    .up-usernotiimg {
        margin: 0;
        float: left;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin: 0 15px 0 0;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}

.up-notificationcontent {
    float: left;
    width: calc(100% - 59px);

    h4 {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        font-family: 'Montserrat', sans-serif;

        time {
            float: right;
            font-size: 14px;
            color: #707070;
            line-height: 17px;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .up-description {
        p {
            margin: 0;
            font-size: 14px;
            color: #707070;
            line-height: 22px;
            font-family: 'Montserrat', sans-serif;
        }
    }
}

.up-notificationdropdown {
    .up-btn {
        border: 0;
        width: 100%;
        float: left;
        color: #fff;
        line-height: 58px;
        padding: 0 !important;
        background: #0f1588;
        border-radius: 0 0 12px 12px;

        &:hover {
            background: #0f1588;
        }
    }
}

.up-sidebarwrapper {
    left: 0;
    top: 86px;
    width: 70px;
    flex-grow: 1;
    display: flex;
    overflow: auto;
    position: fixed;
    flex-direction: column;
    transition: 167ms ease;
    height: calc(100vh - 86px);

    &::-webkit-scrollbar {
        width: 0;
    }

    &:hover {
        width: 220px;
    }
}

.navigation {
    float: left;
    width: 70px;
    height: 100vh;
    transition: 167ms ease;
    background-color: #0f1587;
    .active {
        max-width: 70px;
    }
}


.up-openclose-sidebar-menu {

    .navigation,
    .up-sidebarwrapper {
        width: 190px;
    }
}

.nav_list {
    flex: 1;
    width: 100%;
    float: left;
    list-style: none;
    padding: 35px 0 0;
    margin: 0 !important;
}

.list-item {
    width: 100%;
    float: left;
    display: flex;
    text-align: center;
    padding: 25px 20px;
    position: relative;
    align-items: center;
    list-style-type: none;
    justify-content: flex-start;
    transition: background 166ms ease;

    a {
        gap: 16px;
        width: 100%;
        float: left;
        display: flex;
        color: #333;
        position: relative;
        white-space: nowrap;
        align-items: center;
        text-decoration: none;

        span {
            top: 50%;
            left: 160%;
            display: none;
            font-size: 12px;
            padding: 0 15px;
            min-width: 60px;
            color: #0f1588;
            max-width: 140px;
            line-height: 26px;
            text-align: center;
            position: absolute;
            background: #fff;
            border-radius: 6px;
            white-space: nowrap;
            font-family: 'Montserrat', sans-serif;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            -webkit-box-shadow: 0 0 20px 0 rgba(255, 255, 255, 1);
            box-shadow: 0 0 20px 0 rgba(255, 255, 255, 1);
            border: 1px solid rgba(15, 21, 135, 0.2);

            &:after {
                top: 50%;
                width: 0;
                height: 0;
                right: 100%;
                content: '';
                position: absolute;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                border-top: 5px solid transparent;
                border-right: 10px solid #fff;
                border-bottom: 5px solid transparent;
            }
        }

        &:hover {

            i,
            em {
                color: var(--Malibu);
            }

            span {
                display: block;
            }
        }

        em {
            left: 1000%;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            position: absolute;
            transition: 167ms ease;
        }
    }
}

.up-openclose-sidebar-menu {
    a {
        em {
            left: 50px;
        }
    }
}

.list-item:nth-of-type(8) {
    margin-top: 3rem;
}

.list-item:hover::after {
    opacity: 1;
}

.active [data-tooltip]::before {
    opacity: 0;
    top: 0.25rem;
    left: 4.5rem;
    width: 5.5rem;
    display: none;
    font-weight: 600;
    position: absolute;
    padding: 0.5em 1em;
    align-items: center;
    display: none;
    border-radius: 0.5em;
    pointer-events: none;
    color: var(--gray600);
    transform-origin: left;
    justify-content: center;
    content: attr(data-tooltip);
    text-transform: capitalize;
    background-color: #ffffff40;
    box-shadow: 0 10px 18px -7px #0002;
}

.active [data-tooltip]:hover::before {
    animation: showTooltip 200ms linear 100ms forwards;
}

@keyframes showTooltip {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.list-item i {
    color: #fff;
    font-size: 24px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
}

/* =============================================
			Login Style					
============================================= */
.up-loginarea {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: var(--Malibu);
}

.up-circlimages {
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.up-logincontentarea {
    width: 50%;
    z-index: 2;
    float: left;
    height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding: 40px 40px 40px 110px;

    h3 {
        color: #fff;
        font-size: 36px;
        font-weight: 300;
        line-height: 46px;
        font-family: 'Poppins', sans-serif;

        span {
            display: block;
        }
    }

    .up-description {
        max-width: 525px;

        p {
            color: #fff;
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.up-loginformholder {
    top: 0;
    z-index: 2;
    right: 160px;
    width: 520px;
    height: 100vh;
    overflow: auto;
    position: fixed;
    text-align: left;
    min-height: 655px;
    background: #fff;
    padding: 45px 75px 60px;

    .up-logo {
        padding: 0;
        float: left;
        clear: both;
        width: 170px;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

.up-loginformholder::-webkit-scrollbar {
    width: 0;
}

.up-formsignin {
    fieldset {
        .up-btn {
            float: right;
            border-color: rgba(15, 21, 135, 0.25);
        }
    }
}

.up-formHead {
    width: 100%;
    float: left;
    margin: 50px 0 0;

    h1 {
        font-size: 42px;
        margin: 0 0 10px;
        line-height: 45px;

    }

    span {
        width: 100%;
        float: left;
        font-size: 14px;
        margin: 0 0 45px;
        line-height: 26px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;

        em {
            display: block;
            font-style: normal;
        }
    }
}

.up-btnforgotpassword {
    float: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: var(--Malibu);
    font-family: 'Montserrat', sans-serif;
}

.up-remebermearea {
    float: left;

    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox]+label {
        margin: 0;
        width: 100%;
        float: left;
        cursor: pointer;
        position: relative;
        padding: 0 0 0 25px;

        &:before {
            left: 0;
            top: 50%;
            content: '';
            width: 12px;
            height: 12px;
            background: #fff;
            position: absolute;
            border-radius: 2px;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            border: 1px solid rgba(112, 112, 112, 0.35);
        }
    }

    input[type=checkbox]:checked+label {
        &:before {
            content: '';
            background: var(--Malibu);
            border-color: var(--Malibu);
        }
    }
}

.up-alreadyahveaccount {
    width: 100%;
    float: left;
    padding: 20px 0 0;
    border-top: 1px solid rgba(112, 112, 112, 0.25);

    h2 {
        margin: 0;
        color: #707070;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        text-transform: none;
        font-family: 'Montserrat', sans-serif;

        a {
            color: var(--Malibu);
        }
    }
}

.up-loginformcontentholder {
    float: left;
    width: 100%;
    overflow: auto;
    min-height: 768px;
}

/* =============================================
			Forgot Password Style				
============================================= */
.up-forgotpasswordpage {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: var(--Malibu);

    .up-circlimages {
        top: 50%;
        left: 50%;
        width: 1220px;
        height: 1220px;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .up-loginformholder {
        .up-logo {
            float: none;
            display: block;
            margin: 0 auto;
            overflow: hidden;
        }

        .up-loginformcontentholder {
            .up-formHead {
                h1 {
                    font-size: 24px;
                    line-height: 1.2;
                    margin: 0 0 70px;
                    text-align: center;
                    text-transform: uppercase;
                }

                span {
                    margin: 0 0 20px;
                }
            }
        }
    }
}

.up-formbuttons {
    width: 100%;
    float: left;
    margin: 40px 0 0;

    .up-btn+.up-btn {
        margin: 10px 0 0;
        background: #0f1588;

        &:hover {
            background: #0a0e5f;
        }
    }
}

/* =============================================
			Reset Password Style				
============================================= */
.up-resetpasswordpage {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: var(--Malibu);

    .up-circlimages {
        top: 50%;
        left: 50%;
        width: 1220px;
        height: 1220px;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .up-loginformholder {
        .up-logo {
            float: none;
            display: block;
            margin: 0 auto;
            overflow: hidden;
        }

        right: 50%;
        padding: 45px 60px 60px;
        -webkit-transform: translateX(50%);
        // -moz-transform: translateX(50%);
        -ms-transform: translateX(50%);
        -o-transform: translateX(50%);
        transform: translateX(50%);

        .up-loginformcontentholder {
            .up-formHead {
                h1 {
                    font-size: 24px;
                    line-height: 1.2;
                    margin: 0 0 70px;
                    text-align: center;
                    text-transform: uppercase;
                }

                span {
                    margin: 0 0 20px;
                }
            }
        }
    }
}

/* =============================================
			Sign Up Style				
============================================= */
.up-signuparea {
    .up-loginformholder {
        padding: 45px 60px 60px;
    }
}

.up-formsignup {
    fieldset {
        .form-group {
            .up-btn {
                float: right;
            }
        }
    }
}

.up-signupsteptwo {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: var(--Malibu);

    .up-loginformholder {
        display: flex;
        transform: none;
        align-items: center;
        padding: 45px 60px 60px;

        .up-logo {
            float: none;
            display: block;
            overflow: hidden;
            margin: 0 auto 50px;
        }
    }
}

.up-formsignupsteptwo {
    fieldset {
        legend {
            width: 100%;
            float: left;
            font-size: 24px;
            margin: 0 0 20px;
            text-align: center;
            color: var(--PaynesGrey);
        }

        .up-uploadprofilepic {
            width: 100%;
            height: auto;
            position: static;
        }

        .up-formbuttons {
            margin: 35px 0 0;
            text-align: center;
        }
    }
}

.up-uploadprofilepic {
    width: 100%;
    float: left;
}

.up-profilepic {
    width: 117px;
    height: 117px;
    display: flex;
    margin: 0 auto;
    position: relative;
    border-radius: 50%;
    align-items: center;
    background: #4472c4;
    flex-direction: column;
    justify-content: center;

    >i {
        color: #fff;
        font-size: 55px;
        line-height: 55px;
    }

    img {
        width: 55px;
        height: auto;
        display: block;
    }

    span {
        width: 100%;
        float: left;
        color: #fff;
        font-size: 10px;
        margin: 10px 0 0;
        line-height: 10px;
        text-align: center;
    }
}

.up-uploadimageicon {
    left: 50%;
    top: -12px;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

    input[type=file] {
        display: none;
    }

    input[type=file]+label {
        margin: 0;
        width: 100%;
        width: 24px;
        height: 100%;
        height: 24px;
        display: flex;
        cursor: pointer;
        font-size: 12px;
        color: #5ab5df;
        line-height: 24px;
        border-radius: 50%;
        background: #fff;
        align-content: center;
        justify-content: center;
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

        i {
            line-height: inherit;
        }
    }
}

.up-btnskip {
    font-size: 14px;
    margin: 10px 0 0;
    color: #707070;
    line-height: 16px;
    display: inline-block;
    vertical-align: middle;
}

.up-singupsteptwocontent {
    width: 100%;
    float: left;
    max-width: 580px;
    position: relative;

    h3 {
        width: 100%;
        float: left;
        color: #fff;
        font-size: 42px;
        line-height: 1.2;
        font-weight: 500;
        margin: 0 0 25px;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;

        span {
            display: block;
            font-size: 36px;
            font-weight: 300;
            line-height: 36px;
            text-transform: none;
        }
    }
}

.up-bulletpoints {
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    font-size: 14px;
    list-style: none;
    line-height: 16px;

    li {
        width: 100%;
        float: left;
        padding: 12px 0;
        line-height: inherit;
        list-style-type: none;

        span {
            color: #fff;
            display: block;
            position: relative;
            padding: 0 0 0 45px;

            &:before {
                left: 0;
                top: 50%;
                width: 8px;
                height: 8px;
                content: '';
                position: absolute;
                background: #fff;
                border-radius: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }
}

.up-manvectorimage {
    top: 0;
    right: 0;
    z-index: -1;
    width: 310px;
    height: auto;
    position: absolute;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.up-signupstepthree {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: var(--Malibu);

    .up-loginformholder {
        display: block;
    }

    .up-formbuttons {
        text-align: center;
    }
}

.up-signupthreehead {
    width: 100%;
    float: left;
    margin: 0 0 45px;
    text-align: center;
    position: relative;

    h3 {
        margin: 0;
        font-size: 24px;
        line-height: 26px;
        color: var(--PaynesGrey);
    }
}

.up-btngoback {
    left: 0;
    top: 50%;
    font-size: 16px;
    line-height: 16px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.up-privacysetting,
.up-privacysettings,
.up-privacysettingtitle {
    width: 100%;
    float: left;
}

.up-privacysettingtitle {
    h4 {
        color: #000;
        font-size: 14px;
        margin: 0 0 20px;
        font-weight: 600;
        line-height: 16px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-privacysetting {
    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        list-style: none;

        li {
            width: 100%;
            float: left;
            display: flex;
            line-height: normal;
            align-content: center;
            list-style-type: none;
            justify-content: space-between;
            margin: 15px 0 0;
            &:first-child {
                margin: 0;
            }

            span {
                font-size: 14px;
                color: #707070;
                font-weight: 300;
                line-height: 26px;
                font-family: 'Montserrat', sans-serif;
            }

            .up-select {
                width: 110px;

                &:after {
                    right: 5px;
                    line-height: 26px;
                }

                select {
                    height: 26px;
                    padding: 0 10px;
                    border-radius: 5px;
                    border-color: #0f1587;
                    color: var(--PaynesGrey);
                }
            }
        }
    }
}

.up-privacysetting+.up-privacysetting {
    margin: 15px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid rgba(112, 112, 112, 0.24);
}

/* =============================================
			Follow Organizers Style				
============================================= */
.up-followorgaizerspage {
    width: 100%;
    float: left;
    padding: 80px 0;

    .up-logo {
        float: none;
        width: 170px;
        display: block;
        overflow: hidden;
        margin: 0 auto 60px;
    }
}

.up-container {
    clear: both;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1180px;

    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

.up-followerhead {
    width: 100%;
    float: left;
    margin: 0 0 60px;

    h2 {
        font-size: 36px;
        font-weight: 300;
        margin: 0 0 50px;
        color: #707070;
        line-height: 38px;
        text-align: center;
        text-transform: uppercase;
    }
}

.up-followorganizers {
    width: 100%;
    float: left;

    .up-btn {
        width: 100%;
        display: block;
        margin: 0 auto;
        max-width: 390px;
        min-width: 390px;
    }
    .nav-tabs {
        margin: 0 0 40px;
        .nav-link.active{
            max-width: inherit !important;
        }
    }
}

.up-formsearchorganisation {
    fieldset {
        clear: both;
        margin: 0 -10px ;
        // padding: 20px 25px;
        // background: #fff;
        // border-radius: 8px;
        // border: 1px solid rgba(112, 112, 112, 0.25);

        .form-group {
            width: calc(25% - 20px);
            margin: 0 10px;
            position: relative;

            & > input {
                padding: 10px 65px 10px 20px;
                height: 60px;
            }
            & > .up-btnsearch{
                height: 60px;
            }
        }
    }

    .form-control::-webkit-input-placeholder {
        color: #e4e4e4;
    }

    .form-control:-moz-placeholder {
        color: #e4e4e4;
    }

    .form-control::-moz-placeholder {
        color: #e4e4e4;
    }

    .form-control:-ms-input-placeholder {
        color: #e4e4e4;
    }

    .form-control::-ms-input-placeholder {
        color: #e4e4e4;
    }
}

.up-btnsearch {
    top: 0;
    right: 0;
    z-index: 2;
    width: 60px;
    height: 45px;
    color: #fff;
    font-size: 18px;
    position: absolute;
    background: var(--Malibu);
    border-radius: 0 8px 8px 0;
}

.up-followorganizersholder {
    z-index: 1;
    clear: both;
    display: grid;
    margin: 0 0 75px;
    position: relative;
    grid-gap: 50px 24px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    
}

.up-organisercard {
    width: 100%;
    float: left;
    background: #fff;
    border-radius: 8px;
}

.up-organisercoverimg {
    margin: 0;
    width: 100%;
    float: left;
    height: 100px;
    overflow: hidden;
    border-radius: 8px 8px 0 0;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
    }
}

.up-organisercardcontent {
    width: 100%;
    float: left;
    padding: 25px 25px 35px;
}

.up-organiserlogo {
    width: 100px;
    padding: 5px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    margin: -80px auto 0;
    background: transparent;
    border: 2px dashed #fff;

    &:after {
        top: 0;
        left: 0;
        content: '';
        width: 102px;
        height: 102px;
        border-radius: 50%;
        position: absolute;
        filter: blur(10px);
        background: rgba(0, 0, 0, 0.16);
        -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
    }

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-organisername {
    width: 100%;
    float: left;
    text-align: center;
    margin: 15px 0 25px;

    h3 {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;

        a {
            width: 100%;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    span {
        display: block;
        font-size: 12px;
        font-weight: 400;
        color: #707070;
        line-height: 12px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-organisermetadata {
    width: 100%;
    float: left;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;

    li {
        float: left;
        padding: 0 40px;
        position: relative;
        text-align: center;
        line-height: normal;
        list-style-type: none;

        &:before {
            top: 0;
            left: 0;
            width: 1px;
            content: '';
            height: 100%;
            position: absolute;
            background: rgba(112, 112, 112, 0.24);
        }

        &:first-child {
            &:before {
                display: none;
            }
        }

        h4 {
            font-size: 18px;
            margin: 0 0 5px;
            color: #3f414d;
            line-height: 18px;
            font-family: 'Montserrat', sans-serif;
        }

        span {
            display: block;
            font-size: 12px;
            color: #707070;
            line-height: 12px;
            font-family: 'Montserrat', sans-serif;
        }
    }
}

.up-cardtbns {
    width: 100%;
    float: left;
    margin: 25px 0 0;
    text-align: center;

    .up-btn {
        min-width: 150px;
        background: #fff;
        color: var(--Malibu);
        border-color: var(--Malibu);

        &:hover {
            color: #fff;
            background: var(--Malibu);
        }
    }
}

/* =============================================
			Chat Sidebar Style					
============================================= */
.up-chatwrapper {
    top: 0;
    right: 0;
    z-index: 2;
    width: 84px;
    height: 100vh;
    position: fixed;
}

.up-btnchat {
    width: 100%;
    height: 86px;
    display: flex;
    color: #fff;
    font-size: 27px;
    line-height: 27px;
    align-items: center;
    justify-content: center;
    background: var(--Malibu);
}

.up-onlinememberholder {
    width: 100%;
    float: left;
    overflow: auto;
    background: #fff;
    height: calc(100vh - 86px);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -webkit-box-shadow: -5px 0 15px 0 rgba(0, 0, 0, 0.2);
    box-shadow: -5px 0 15px 0 rgba(0, 0, 0, 0.2);
}

.up-onlinememberholder::-webkit-scrollbar {
    width: 0;
}

.up-chatusers {
    margin: 0;
    width: 100%;
    float: left;
    list-style: none;
    padding: 12px 0 0;

    li {
        width: 100%;
        float: left;
        padding: 12px;
        cursor: pointer;
        line-height: normal;
        list-style-type: none;
    }

    .up-active {
        background: rgba(90, 181, 223, 0.24);
    }
}

.up-chatusersimg {
    width: 56px;
    height: 56px;
    padding: 4px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px dashed #e4e4e4;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-onlinedot {
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    border: 3px solid #fff;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.up-onlinecolor {
    background: #6bdd1f;
}

.up-offlinecolor {
    background: #e4e4e4;
}

.up-btnaddtofriendlist {
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    font-size: 8px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    color: var(--PaynesGrey);
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.up-selecfilesarea {
    width: 100%;
    float: left;
    margin: 0 0 10px;
    background: #fff;
    border-radius: 14px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.up-filesholder {
    clear: both;
    margin: 0 -4px;

    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

.up-selectedfile {
    float: left;
    margin: 4px;
    position: relative;
    border-radius: 5px;
    width: calc(20% - 4px);
    padding: 5px 20px 5px 5px;
    background: rgba(90, 181, 223, 0.24);
}

.up-fileimage {
    width: 50px;
    float: left;
    height: 50px;
    margin: 0 10px 0 0;
    border-radius: 5px 0 0 5px;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 5px 0 0 5px;
    }
}

.up-btndeleteimg {
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    color: #fff;
    display: flex;
    font-size: 8px;
    background: red;
    position: absolute;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.up-filenamesizearea {
    float: left;
    width: calc(100% - 60px);

    h4 {
        width: 100%;
        float: left;
        color: #000;
        font-size: 14px;
        overflow: hidden;
        line-height: 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    span {
        width: 100%;
        float: left;
        font-size: 12px;
        line-height: 15px;
    }
}

.up-chatfilesarea {
    width: 100%;
    float: left;
    padding: 5px;
}

.up-chatfilesholder {
    width: auto;
    float: revert;
    // display: grid;
    // grid-gap: 8px;
    // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.up-chatfile {
    float: left;
    margin: 2px;
    // width: 100px;
    width: 100%;
}

.up-chatfileimage {
    margin: 0;
    width: 100%;
    float: left;
    height: 240px;
    border-radius: 5px;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 5px;
    }

    video {
        width: 100%;
        float: left;
        height: 100%;
        border-radius: 5px;

        source {
            width: 100%;
            height: 100%;
        }
    }
    i{
        color: #999;
        font-size: 48px;
    }
}

.at-chatfilename {
    width: 100%;
    float: left;
    padding: 10px;

    h4 {
        width: 100%;
        float: left;
        color: #000;
        font-size: 14px;
        margin: 0 0 2px;
        overflow: hidden;
        line-height: 17px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    span {
        width: 100%;
        float: left;
        font-size: 12px;
        line-height: 15px;
    }
}

.up-btndownload {
    width: 100%;
    float: left;
    margin: 5px 0 0;

    .up-btn {
        border: 0;
        font-size: 12px;
        padding: 0 10px;
        line-height: 25px;
        border-radius: 5px;
    }
}

.up-chatfileicon {
    .up-chatfileimage {
        display: flex;
        align-items: center;
        justify-content: end;
        // background: rgba(90, 181, 223, 0.24);
        height: 50px !important;

        i {
            color: #999;
            font-size: 44px;
            line-height: 44px;
        }
    }
}

.up-videofile {
    // height: 100%;
    display: flex;
    // min-height: 240px;
    // max-height: 240px;
    align-items: center;
}

.up-bottomchat {
    bottom: 0;
    z-index: 3;
    right: 120px;
    height: 350px;
    width: 350px !important;
    position: fixed;

    .up-chatmessagearea {
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0 0;
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

        .up-chatheadarea {
            padding: 10px;
            background: #0f1587;

            .up-chatuser {
                .up-chatusersimg {
                    width: 30px;
                    height: 30px;
                }

                .up-chatusercontent {
                    width: calc(100% - 45px);

                    .up-chatusercontenthead {
                        h3 {
                            color: #fff;
                        }
                    }
                }
            }
        }

        .up-chatmessagesholder {
            height: calc(100% - 50px);
            padding: 15px 10px;
        }
    }

    .up-chatfilesholder {
        .up-chatfile {
            // width: calc(50% - 4px);

            .up-chatfileimage {
                height: 100px;
            }
        }
    }

    .up-writemessagearea {
        padding: 10px;

        .up-formwritemessages {
            fieldset {
                .form-group {
                    textarea {
                        // position: relative !important;
                        // height: 40px !important;
                        font-size: 14px;
                        // color: #fff !important;
                        border-radius: 35px;
                        // background: #fff;
                        padding: 8px 130px 8px 10px;
                    }
                     
                }
            }
        }
    }
}

.up-btnclosechat {
    font-size: 14px;
    line-height: 14px;
    color: #fff !important;
}
/* =============================================
			Home Page Style					
============================================= */
.up-homepage,
.up-twocolumns {
    width: 100%;
    float: left;
}

.up-twocolumns {
    margin: 30px 0 0;
}

.up-homepage .up-twocolumns {
    margin: 0;
}

.up-themebanner {
    width: 100%;
    float: left;
    overflow: hidden;
    min-height: 145px;
    position: relative;
    border-radius: 14px;
    background: var(--Malibu);
}

.up-bannerpatterenimage {
    top: 0;
    right: 0;
    margin: 0;
    height: 100%;
    width: 470px;
    position: absolute;
}

.up-bannerimg {
    right: 0;
    margin: 0;
    bottom: 0;
    z-index: 1;
    width: 386px;
    position: absolute;
}

.up-themebannercontent {
    left: 55px;
    bottom: 40px;
    position: absolute;

    h2 {
        margin: 0;
        color: #fff;
        font-size: 36px;
        font-weight: 500;
        line-height: 1.3;
        font-family: 'Poppins', sans-serif;
    }
}

.up-postsholder {
    float: left;
    width: calc(100% - 355px);
}

.up-sidebar {
    float: right;
    width: 320px;
}

.up-post,
.up-postsarea {
    width: 100%;
    float: left;
}

.up-post {
    padding: 30px;
    background: #fff;
    border-radius: 12px;
    min-height: 150px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

.up-post+.up-post {
    margin: 35px 0 0;
}

.up-posthead {
    width: 100%;
    float: left;
    margin: 0 0 20px;
}

.up-postnameuserimage {
    width: 100%;
    float: left;
    display: flex;
    position: relative;
    align-items: center;

    .up-themedropdown {
        top: 0;
        right: 0;
        position: absolute;

        button {
            color: var(--PaynesGrey);
        }

        .up-themedropdownmenu {
            right: 0 !important;
            top: 22px !important;
            left: auto !important;
            width: 155px !important;
            padding: 0 20px !important;

            &:after {
                display: none;
            }
        }
    }

    .btn-check:active+.btn-primary,
    .btn-check:checked+.btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show>.btn-primary.dropdown-toggle {
        color: var(--PaynesGrey);
    }

    .up-userimage {
        width: 56px;
        padding: 4px;
        height: 56px;
        margin: 0 15px 0 0;
        border: 1px dashed #e4e4e4;

        img {
            border-radius: 50%;
        }
    }
}

.up-postheadcontent {
    float: left;
    padding: 0 25px 0 0;
    width: calc(100% - 71px);

    h3 {
        margin: 0 0 8px;
        font-size: 14px;
        line-height: 1.3;
        color: #707070;

        a {
            color: #000;
            font-weight: 600;
        }
    }

    span {
        width: 100%;
        float: left;
        font-size: 12px;
        color: #707070;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-postdescription {
    width: 100%;
    float: left;
    margin: 0 0 20px;

    p,
    pre {
        margin: 0;
        color: #000;
        font-size: 14px;
        line-height: 24px;
        white-space: pre-wrap;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-postsimagearea {
    width: 100%;
    float: left;
    margin: 0 0 20px;

    .up-postimage {
        .icon-file {
            width: 100%;
            height: 100%;
            display: flex;
            font-size: 60px;
            color: #5ab4de;
            position: absolute;
            align-items: center;
            justify-content: center;
            background: rgba(90, 181, 223, 0.15);
        }
    }
    video{
        width: 100% !important;
        height: 300px;
    }
}

.up-postimage {
    margin: 0;
    height: 300px;
    overflow: hidden;
    border-radius: 12px;
    display: table-cell;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }
}

.up-postreactionsharearea {
    width: 100%;
    float: left;
}

.up-reactpostholder {
    float: left;
    display: none;
}

.up-reactdropdown {
    button {
        width: 15px;
        float: left;
        height: 15px;

        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .up-themedropdownmenu {
        top: -53px !important;
        width: 155px !important;
        bottom: auto !important;
        padding: 10px 0 !important;
        text-align: center !important;
        border-radius: 25px !important;

        &:after {
            display: none;
        }

        a {
            width: auto;
            border: 0 !important;
            display: inline-block;
            vertical-align: top;
            padding: 0 4px !important;
        }
    }
}

.up-sharecommentholder {
    float: right;

    .up-reactdropdown {
        float: right;

        .up-themedropdownmenu {
            right: 0 !important;
            left: auto !important;
        }
    }
}

.up-btncomments {
    // float: left;
    font-size: 14px;
    color: #707070;
    line-height: 14px;
    margin: 0 25px 0 0;
}

.up-sharefacebokkicon{
    color: #707070 !important;
    font-weight: 400 !important;
    width: 100% !important;
    height: auto !important;
}

.up-commentsarea {
    width: 100%;
    float: left;
    padding: 35px 0 0;
    background: #fff;

    .up-sectiontitle {
        h3 {
            float: left;
            margin: 3px 0 0 0;
        }
    }
}

.up-sectiontitle {
    width: 100%;
    float: left;
    margin: 0 0 35px;

    h3 {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;
    }
}

.up-comments {
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    list-style: none;
    margin: -12px 0 20px;

    li {
        width: 100%;
        float: left;
        padding: 12px 0;
        list-style-type: none;
    }

    li+li {
        .up-avatar {
            margin: 25px 15px 0 0;
        }

        .up-commentscontent {
            padding: 25px 0 0;
            border-top: 1px solid rgba(112, 112, 112, 0.24);
        }
    }
}

.up-comment {
    width: 100%;
    float: left;
    position: relative;
}

.up-avatar {
    float: left;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 0 15px 0 0;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-commentscontent {
    float: left;
    width: calc(100% - 70px);

    h4 {
        float: left;
        color: #000;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        font-family: 'Montserrat', sans-serif;

        time {
            font-size: 10px;
            color: #707070;
            line-height: 16px;
            margin: 0 0 0 10px;
        }
    }

    .up-description {

        p,
        pre {
            white-space: pre-wrap;
            margin: 0;
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.up-formwritemessage {
    fieldset {
        width: auto;
        float: none;
        clear: both;
        padding: 25px 35px;
        border-radius: 12px;
        background: #f5f5f5;
        margin: 0 -30px -30px;

        .form-group {
            margin: 0;
            position: relative;
            padding: 0 180px 0 60px;

            .up-btn {
                top: 0;
                right: 0;
                position: absolute;
            }

            textarea {
                // position: relative !important;
                width: 100%;
                float: left;
                // height: 47px !important;
                resize: none;
                padding: 9px 9px 9px 9px !important;
                // color: #fff !important;
                // background: #fff !important;
                border-radius: 40px;
                border: 1px solid rgba(112, 112, 112, 0.24);
                // & ~ div{display: none;}
            }
        }
    }
}

.up-ownimage {
    top: 0;
    left: 0;
    margin: 0;
    width: 46px;
    height: 46px;
    position: absolute;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-addpost {
    display: flex;
    height: 140px;
    min-height: 140px;
    align-items: center;
    justify-content: center;
    background: rgba(63, 65, 77, 0.24);

    h4 {
        margin: 0;
        color: #fff;
        font-size: 52px;
        font-weight: 600;
        line-height: 50px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-sharemodalcontent {
    width: 100%;
    float: left;

    .up-select {
        &:after {
            content: url('assets/images/arrow-down.png');
        }
    }
}

.up-singleimage {
    .up-postimage {
        display: block;
    }
}

.up-twoimages {
    width: auto;
    float: none;
    clear: both;
    margin: 0 -5px 20px;

    &:after {
        clear: both;
        content: '';
        display: block;
    }

    .up-postimage {
        float: left;
        margin: 5px;
        width: calc(50% - 10px);
    }
}

/* =============================================
			Sidebar Widget Style					
============================================= */
.up-widget {
    width: 100%;
    float: left;
    padding: 20px;
    position: relative;
    background: #fff;
    border-radius: 12px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.up-widget+.up-widget {
    margin: 35px 0 0;
}

.up-widgethead {
    width: 100%;
    float: left;
    padding: 0 0 35px;

    h3 {
        margin: 0;
        font-size: 18px;
        color: #707070;
        line-height: 18px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-themecalendarholder {
    width: 100%;
    float: left;
}

.react-calendar {
    float: left;
    border: 0 !important;
    width: 100% !important;

    .react-calendar__navigation {
        height: auto;
        display: block !important;

        .react-calendar__navigation__arrow {
            display: none;
        }

        .react-calendar__navigation__label {
            color: #000;
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
            font-family: 'Montserrat', sans-serif;

            &:hover {
                background: none;
                color: var(--Malibu);
            }
        }
    }

    .react-calendar__month-view__weekdays__weekday {
        abbr {
            font-size: 14px;
            color: #4472c4;
            font-weight: 500;
            line-height: 16px;
            text-transform: none;
            text-decoration: none;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .react-calendar__tile {
        padding: 10px 0;
        
        &:hover {
            background: none;
        }
        
        abbr {
            border-radius: 4px;
            color: #000;
            width: 32px;
            height: 32px;
            float: left;
            font-size: 14px;
            font-weight: 500;
            line-height: 32px;
            font-family: 'Montserrat', sans-serif;
        }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        abbr {
            color: #ababab;
        }
    }

    .react-calendar__tile--active {
        color: #fff;
        background: none;
        
        abbr {
            color: #fff;
            background: #5ab5df;
        }
    }
    .react-calendar__tile--now{
        background: none;
        abbr{
            color: #fff;
            background: #0f1588;
        }
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        background: none;
    }
}

.up-calendareventsarea {
    width: 100%;
    float: left;
}

.up-calendarevents {
    margin: 0;
    width: 100%;
    float: left;
    list-style: none;
    padding: 20px 0 0;

    li {
        width: 100%;
        float: left;
        padding: 20px 0;
        list-style-type: none;
    }

    li+li {
        border-top: 1px solid rgba(112, 112, 112, 0.12);
    }
}

.up-calendarevent {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.up-eventimage {
    float: left;
    width: 65px;
    height: 65px;
    margin: 0 12px 0 0;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-eventcontent {
    float: left;
    width: calc(100% - 77px);

    h4 {
        margin: 0 0 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;
    }

    span {
        display: block;
        font-size: 12px;
        margin: 0 0 6px;
        font-weight: 500;
        color: #0f1588;
        line-height: 14px;
        font-family: 'Montserrat', sans-serif;
    }

    time {
        display: block;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;
    }
}

.up-widgetadd {
    padding: 0;
    height: 315px;
    display: flex;
    box-shadow: none;
    max-height: 315px;
    align-items: center;
    background: rgba(63, 65, 77, 0.24);
    justify-content: center;

    h5 {
        margin: 0;
        color: #fff;
        font-size: 52px;
        font-weight: 600;
        line-height: 50px;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
    }
}

.up-adimagholder {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;

    a {
        height: 100%;
        display: block;

        img {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 12px;
        }
    }
}

.up-publicupdates {
    padding: 0;
    width: 100%;
    float: left;
    margin: -20px 0;
    list-style: none;

    li {
        width: 100%;
        float: left;
        padding: 20px 0;
        list-style-type: none;
    }

    li+li {
        border-top: 1px solid rgba(112, 112, 112, 0.12);
    }
}

.up-publicupdate {
    width: 100%;
    float: left;

    h6 {
        font-size: 14px;
        color: #0f1588;
        font-weight: 600;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
    }

    p {
        margin: 0;
        font-size: 14px;
        color: #707070;
        line-height: 20px;
        font-family: "Montserrat", sans-serif;
    }
}

.up-thememodal {
    .modal-dialog {
        width: 100%;
        max-width: 510px;
        overflow: hidden;

        .modal-content {
            border: 0;
            width: 100%;
            float: left;
            display: block;
            border-radius: 12px;
            -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
        }
    }
}

.up-modalheader {
    width: 100%;
    float: left;
    text-align: center;
    position: relative;
    display: block !important;
    padding: 25px 20px !important;

    .close {
        top: 50%;
        opacity: 1;
        right: 20px;
        width: auto;
        height: auto;
        font-size: 30px;
        background: none;
        border-radius: 0;
        line-height: 30px;
        position: absolute;
        margin: 0 !important;
        padding: 0 !important;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .modal-title {
        font-size: 18px;
        line-height: 20px;
        color: var(--PaynesGrey);
        font-family: "Montserrat", sans-serif;
    }

    .btn-close {
        top: 50%;
        opacity: 1;
        left: 100px;
        width: 35px;
        height: 17px;
        background: none;
        border-radius: 0;
        position: absolute;
        margin: 0 !important;
        padding: 0 !important;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);

        &:after {
            top: 0;
            left: 0;
            content: '\e900';
            line-height: 17px;
            position: absolute;
            font-family: 'icomoon';
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.up-modalbody {
    width: 100%;
    float: left;
    padding: 0 !important;
}

.up-modalcontentholder {
    width: 100%;
    float: left;
    padding: 25px;
}

.up-formreport {
    fieldset {
        legend {
            width: 100%;
            float: left;
            font-size: 12px;
            margin: 0 0 15px;
            font-weight: 400;
            line-height: 13px;
            text-align: center;
            color: var(--PaynesGrey);
            font-family: "Montserrat", sans-serif;
        }
    }
}

.up-reportoptions {
    padding: 0;
    width: 100%;
    float: left;
    margin: 0 0 15px;
    list-style: none;

    li {
        width: 100%;
        float: left;
        list-style-type: none;
    }

    li+li {
        margin: 15px 0 0;
    }

    textarea {
        width: 100%;
        float: left;
        resize: none;
        height: 100px;
        font-size: 14px;
        font-weight: 500;
        margin: 15px 0 0;
        line-height: 20px;
        border-radius: 10px;
        color: var(--PaynesGrey);
        font-family: "Montserrat", sans-serif;
        border: 1px solid rgba(112, 112, 112, 0.50);
    }
}

.up-reportoption {
    width: 100%;
    float: left;

    span {
        float: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: var(--PaynesGrey);
        font-family: "Montserrat", sans-serif;
    }

    .up-radio {
        width: auto;
        float: right;

        input[type=radio]+label {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #707070;

            &:before {
                top: 50%;
                left: 50%;
                width: 8px;
                opacity: 0;
                height: 8px;
                content: '';
                visibility: hidden;
                position: absolute;
                border-radius: 50%;
                background: #4472c4;
                -webkit-transform: translateX(-50%) translateY(-50%);
                -moz-transform: translateX(-50%) translateY(-50%);
                -ms-transform: translateX(-50%) translateY(-50%);
                -o-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
            }
        }

        input[type=radio]:checked+label {
            content: '';
            border-color: #4472c4;

            &:before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/* =============================================
			Organizers Page Style				
============================================= */
.up-allorganisationspage {
    width: 100%;
    float: left;

    .up-themebanner {
        margin: 0 0 30px;
        min-height: 286px;

        .up-bannerimg {
            right: 95px;
        }

        .up-bannerpatterenimage {
            width: 100%;
            max-width: 935px;
        }

        .up-themebannercontent {
            top: 50%;
            bottom: auto;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

/* =============================================
		Organiser Profile Page Style				
============================================= */
.up-Organisationprofilepage {
    width: 100%;
    float: left;

    .up-themebanner {
        min-height: 350px;
        max-height: 350px;
        position: relative;
        border-radius: 14px 14px 0 0;

        .up-bannerimg {
            right: auto;
            width: 100%;
            float: left;
            bottom: auto;
            height: 350px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.up-profilebannercontent {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 40px 40px 30px;
    background:
        -moz-linear-gradient(top,
            rgba(255, 255, 255, 0) 69%,
            rgba(0, 0, 0, 0.6) 100%);
    background:
        -webkit-linear-gradient(top,
            rgba(255, 255, 255, 0) 69%,
            rgba(0, 0, 0, 0.6) 100%);
    background:
        linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 69%,
            rgba(0, 0, 0, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#99000000', GradientType=0);
}

.up-profilenameimage {
    left: 40px;
    z-index: 3;
    bottom: 30px;
    display: flex;
    position: absolute;
    align-items: center;
}

.up-organisationprofileimg {
    float: left;
    width: 144px;
    padding: 5px;
    height: 144px;
    border-radius: 50%;
    margin: 0 30px 0 0;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='white' stroke-width='2' stroke-dasharray='50' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 100px;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }

    .up-checkbutton {
        margin: 0;
        bottom: 5px;
        width: 40px;
        height: 40px;
        right: -100px;
        color: #fff;
        display: flex;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        align-items: center;
        justify-content: center;
        background: var(--Malibu);
    }
}

.up-organisationname {
    float: left;

    h3 {
        color: #fff;
        margin: 0 0 5px;
        font-size: 36px;
        line-height: 36px;
        font-family: 'Montserrat', sans-serif;
    }

    span {
        color: #fff;
        display: block;
        font-size: 18px;
        line-height: 18px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-organisermetadatadashboard {
    margin: 0;
    padding: 0;
    right: 45px;
    bottom: 40px;
    font-size: 14px;
    list-style: none;
    line-height: 14px;
    position: absolute;

    li {
        float: left;
        padding: 0 15px;
        line-height: none;
        list-style-type: none;

        &:last-child {
            padding-right: 0;
        }

        &:first-child {
            padding-left: 0;
        }

        span {
            color: #fff;
            display: block;
        }
    }
}

.up-organiserprofiletabarea,
.up-organiserprofiletabholder {
    width: 100%;
    float: left;
    position: relative;
}

.up-profiletabs {
    width: 100%;
    float: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 60px;
    background: #fff;
    border: 0 !important;
    display: block !important;
    list-style: none !important;
    font-family: 'Montserrat', sans-serif;

    li {
        float: left;
        line-height: inherit;
        list-style-type: none;
        border-right: 1px solid rgba(112, 112, 112, 0.25);

        .nav-link {
            padding: 0 10px;
            font-size: 13px;
            color: #707070;
            position: relative;
            border: 0 !important;
            display: block !important;

            &:before {
                left: 0;
                width: 0;
                bottom: 0;
                content: '';
                height: 2px;
                position: absolute;
                border-radius: 30px;
                background: #4472c4;
            }
        }

        .active {
            max-width: none;
            color: #4472c4 !important;

            &::before {
                width: 100%;
            }
        }
    }

    .nav-link {
        padding: 0 10px;
        font-size: 13px;
        color: #707070;
        position: relative;
        border: 0 !important;
        display: inline-block !important;
        border-right: 1px solid rgba(112, 112, 112, 0.25) !important;

        &:before {
            left: 0;
            width: 0;
            bottom: 0;
            content: '';
            height: 2px;
            position: absolute;
            border-radius: 30px;
            background: #4472c4;
        }
    }

    .active {
        max-width: none;
        color: #4472c4 !important;

        &::before {
            width: 100%;
        }
    }
}

.tab-pane,
.up-timlines {
    width: 100%;
    float: left;
}

.tab-pane.active {
    max-width: none;
}

.up-textalignleft {
    padding: 0 0 20px;

    h3 {
        color: #0f1588;
        text-align: left;
    }
}

.up-organisationcontent {
    width: 100%;
    float: left;

    .up-description {
        margin: 0 0 35px;

        p {
            margin: 0;
            color: #000;
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.up-organisationinfo {
    margin: 0;
    padding: 0;
    float: left;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    max-width: 270px;
    list-style: none;
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;

    li {
        width: 100%;
        float: left;
        line-height: inherit;
        list-style-type: none;

        span {
            float: left;
            width: 85px;
            color: #707070;
        }

        span+span {
            word-break: break-word;
            color: #000;
            width: calc(100% - 85px);
        }
    }

    li+li {
        margin: 20px 0 0;
    }
}

.up-websitelink {
    span {
        a {
            display: block;
            color: #0f1588;
        }
    }
}

.up-socialicons {
    float: left;
    width: calc(100% - 85px);

    a {
        width: 18px;
        height: 18px;
        color: #fff;
        margin: 0 3px;
        font-size: 10px;
        border-radius: 50%;
        text-align: center;
        align-items: center;
        display: inline-flex;
        background: #707070;
        vertical-align: middle;
        justify-content: center;
    }
}

.up-twittersocial {
    &:hover {
        color: #fff;
        background: #1da1f2;
    }
}

.up-pinterestsocial {
    &:hover {
        color: #fff;
        background: #bd081c;
    }
}

.up-facebooksocial {
    &:hover {
        color: #fff;
        background: #4267b2;
    }
}

.up-instagramsocial {
    &:hover {
        color: #fff;
        background: #833ab4;
    }
}

.up-member,
.up-membersarea {
    width: 100%;
    float: left;
}

.up-membersarea {
    margin: -10px 0 0;
}

.up-member {
    display: flex;
    margin: 10px 0;
    align-items: center;
}

.up-memberimg {
    margin: 0;
    width: 76px;
    float: left;
    height: 76px;
    padding: 6px;
    margin: 0 18px 0 0;
    border-radius: 50%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23E4E4E4FF' stroke-width='3' stroke-dasharray='5%2c 20' stroke-dashoffset='4' stroke-linecap='round'/%3e%3c/svg%3e");

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-membername {
    float: left;
    position: relative;
    width: calc(100% - 94px);

    h4 {
        color: #000;
        margin: 0 0 5px;
        font-size: 14px;
        line-height: 16px;
        font-family: 'Montserrat', sans-serif;
    }

    span {
        width: 100%;
        float: left;
        font-size: 10px;
        color: #707070;
        line-height: 12px;
    }

    .up-themedropdown {
        top: 0;
        right: 0;
        position: absolute;

        button {
            color: #707070 !important;
        }

        .show>.btn-primary.dropdown-toggle {
            color: #707070 !important;
        }

        .up-themedropdownmenu {
            inset: inherit !important;
            height: max-content;
            top: 25px !important;
            right: 0 !important;
            padding: 0 20px !important;

            &:after {
                display: none;
            }
        }
    }
}

.up-widgetallmembers {
    .up-membersarea {
        overflow: auto;
        max-height: 480px;
    }

    ::-webkit-scrollbar {
        width: 0;
    }
}

.up-widgetallmembers {
    .up-btn {
        display: block;
        overflow: hidden;
        line-height: 54px;
        margin: 0 -20px -20px;
        border-radius: 0 0 12px 12px;
    }
}

.up-organisationcreateddate {
    width: 100%;
    float: left;

    span {
        display: block;
        font-size: 14px;
        line-height: 16px;
        font-family: 'Montserrat', sans-serif;
    }

    h4 {
        font-size: 25px;
        color: #707070;
        line-height: 27px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-organisationaboutinfo {
    width: 100%;
    float: left;
    margin: 30px 0 0;

    .up-themebox {
        padding: 40px;
    }

    .up-description {
        p {
            font-size: 14px;
        }
    }
}

.up-themebox {
    width: 100%;
    float: left;
    padding: 30px;
    background: #fff;
    border-radius: 12px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

.up-themeboxhead {
    width: 100%;
    float: left;
    margin: 0 0 25px;

    h3 {
        margin: 0;
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;
    }
}

.up-organisationhistory,
.up-contactorganisation {
    width: 100%;
    float: left;
}

.up-contactorganisation {
    .up-organisationinfo {
        max-width: none;

        li {
            span {
                width: 126px;
            }

            span+span {
                width: calc(100% - 126px);
            }

            .up-socialicons {
                width: calc(100% - 126px);
            }
        }
    }
}

.up-organisationhistory {
    margin: 40px 0 0;

    .up-organisationinfo {
        max-width: none;

        li {
            span {
                width: 126px;
            }

            span+span {
                width: calc(100% - 126px);
            }
        }
    }
}

.up-allmemberstab {
    width: 100%;
    float: left;
    margin: 30px 0 0;

    .up-membersarea+.up-membersarea {
        margin: 20px 0 0;
    }

    .up-themebox {
        .up-btn {
            width: auto;
            display: block;
            overflow: hidden;
            line-height: 54px;
            margin: 0 -30px -30px;
            border-radius: 0 0 12px 12px;
        }
    }
}

.up-formallmembers {
    margin: 0 0 30px;
    padding: 0 0 30px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.18);

    fieldset {
        .form-group {
            margin: 0;
            position: relative;

            i {
                top: 50%;
                left: 20px;
                position: absolute;
                color: rgba(112, 112, 112, 0.85);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            input {
                border: 0;
                border-radius: 50px;
                padding: 10px 20px 10px 50px;
                -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }
        }
    }
}

.up-memberhead {
    width: 100%;
    float: left;
    margin: 20px 0 10px;

    h4 {
        margin: 0;
        font-size: 20px;
        color: #0f1588;
        line-height: 22px;
    }
}

.up-groupsarea {
    width: 100%;
    float: left;
    margin: 30px 0 0;
}

.up-group,
.up-allgroups {
    width: 100%;
    float: left;
}

.up-allgroups {
    margin: -10px 0;
}

.up-group {
    margin: 10px 0;

    .up-btn {
        border: 0;
        margin: 9px 0;
        float: right;
    }

    .up-themedropdown {
        float: right;
        margin: 17px 0 17px 15px;

        button {
            i {
                color: #000;
            }
        }

        .up-themedropdownmenu {
            top: 25px !important;

            &:after {
                display: none;
            }
        }
    }

    &:last-child {
        .up-themedropdown {
            .up-themedropdownmenu {
                inset: initial !important;
                top: auto !important;
                bottom: 40px !important;
                right: 10px !important;
            }
        }
    }
}

.up-gourpimagename {
    float: left;
    display: flex;
    align-items: center;
}

.up-groupimg {
    float: left;
    width: 60px;
    padding: 4px;
    height: 60px;
    margin: 0 12px 0 0;
    border-radius: 50%;
    border: 1px dashed #e4e4e4;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-groupname {
    width: auto;
    float: left;

    h3 {
        margin: 0;
        text-transform: none;
        font-size: 18px;
        font-weight: 500;
        color: #707070;
        line-height: 20px;
        font-family: 'Montserrat', sans-serif;
        i,
        span{
            float: left;
        }
        p{
            float: left;
            color: #fff;
            font-size: 14px;
            background: #ffa608;
            padding: 5px;
            margin: -6px 0 0 8px;
        }
    }
}
// .up-popupgroupname{
//     width: 250px !important;
// }
.up-btnwithicon {

    i,
    span {
        display: inline-block;
        vertical-align: middle;
    }

    i {
        font-size: 18px;
        margin: 0 0 0 15px;
    }
}

.up-allmediaarea {
    width: 100%;
    float: left;
    margin: 30px 0 0;

    .up-themeboxhead {
        h3 {
            float: left;
            font-size: 36px;
            line-height: 36px;
        }
    }
}

.up-mediatabsarea {
    width: 100%;
    float: left;

    a {
        font-weight: 400;
        margin: 0 !important;
        color: #707070 !important;

        &:focus,
        &:hover {
            border-color: transparent !important;
        }

        &:hover {
            color: #000 !important;
        }
    }

    .active {
        max-width: none;
        border: 0 !important;
        color: #000 !important;
    }
}

.tab-content {
    width: 100%;
    float: left;
}

.up-mediatabs {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    background: #fff;
    border: 0 !important;
    display: block !important;
    list-style: none !important;
    margin-bottom: 30px !important;
    font-family: "Montserrat", sans-serif;

    a {
        border: 0;
        display: inline-block !important;
    }

    li {
        padding: 0;
        float: left;
        position: relative;
        line-height: inherit;
        list-style-type: none;

        button {
            padding: 0;
            display: block;
            color: #707070;
            font-weight: 500;
            position: relative;
            line-height: inherit;
            padding: 0 12px 20px;
            border: 0 !important;
            line-height: inherit;
            border-radius: 0 !important;

            &:after {
                left: 0;
                width: 0;
                bottom: 0;
                content: "";
                height: 2px;
                position: absolute;
                border-radius: 30px;
                background: #4472c4;
            }
        }

        .active {
            border: 0;

            &:after {
                width: 100%;
            }
        }
    }
}

.up-allmediaimages {
    z-index: 1;
    clear: both;
    display: grid;
    grid-gap: 20px;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

.up-mediafile {
    width: 100%;
    float: left;
}

.up-mediaimg {
    margin: 0;
    width: 100%;
    float: left;
    height: 210px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 8px;
    }

    figcaption {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.70);
    }
}

.up-btnvideoplay {
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    color: #fff;
    display: flex;
    border-radius: 50%;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: var(--Malibu);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.up-eventstabsarea {
    width: 100%;
    float: left;
    margin: 30px 0 0;

    .up-eventsholder {
        width: 100%;
    }

    .up-themeboxhead {
        h3 {
            float: left;
            font-size: 36px;
            line-height: 36px;
        }

        .up-btn {
            border: 0;
            float: right;
        }
    }

    .up-alleventstwocols {
        .up-event {
            // .up-eventhead {
            //     margin: 0;
            // }

            .up-eventbuttons {
                .up-themedropdown {
                    margin: 7px 0;

                    button {
                        color: #000;
                    }
                }
            }

            .up-themedropdownmenu {
                top: 25px !important;

                &:after {
                    display: none;
                }
            }

            &:last-child {
                .up-themedropdown {
                    .up-themedropdownmenu {
                        inset: initial !important;
                        top: -110px !important;
                        right: 10px !important;
                    }
                }
            }
        }
    }
}

.up-alleventstwocols {
    width: 100%;
    float: left;
}

.up-eventsholder {
    float: left;
    width: calc(100% - 350px);
}

.up-event,
.up-eventhead,
.up-alleventsholder {
    width: 100%;
    float: left;

    h5 {
        font-size: 24px;
        color: #0f1588;
        line-height: 24px;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-event {
    padding: 25px 0;
}

.up-event+.up-event {
    border-top: 1px solid rgba(112, 112, 112, 0.16);
}

.up-eventhead {
    margin: 0 0 15px;
}

.up-eventnameimgdate {
    float: left;
    display: flex;
    align-items: center;
}

.up-eventlogoimg {
    float: left;
    width: 68px;
    height: 68px;
    margin: 0 15px 0 0;
    border-radius: 50%;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-eventnamedate {
    width: calc(100% - 90px);
    float: left;

    h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 26px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;
    }

    time {
        width: 100%;
        float: left;
        font-size: 14px;
        color: #0f1588;
        line-height: 16px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-eventbuttons {
    margin: 0;
    padding: 0;
    float: right;
    list-style: none;

    li {
        float: left;
        padding: 0 8px;
        list-style-type: none;

        .up-btn {
            border: 0;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}

.up-btnclose {
    border: 0;
    min-width: 140px;
    background: #0f1588;
}

.up-btnjoined {
    color: #fff;
    background: #3f414d;
}

.up-btnviewdetails {
    color: #fff;
    background: var(--Malibu);
}

.up-eventsdetailsinfo {
    width: 100%;
    float: left;
    padding: 30px;
    border-radius: 10px;
    background: #eff4fb;
    border: 1px solid rgba(15, 21, 135, 0.16);
}

.up-eventdescriptions {
    width: 100%;
    float: left;

    h4 {
        // width: 100%;
        float: left;
        font-size: 20px;
        margin: 0 0 15px;
        color: #707070;
        line-height: 22px;
        font-family: 'Montserrat', sans-serif;
    }
    .up-btnshowallmembers{
        float: right;
        font-weight: 600;
        text-decoration: underline;
    }

    .up-description {
        margin: 25px 0;

        p,
        pre {
            margin: 0;
            color: #000;
            font-size: 14px;
            line-height: 22px;
            white-space: pre-wrap;
        }
    }
}

.up-eventotherinfo {
    width: 100%;
    float: left;

    h5 {
        width: 100%;
        float: left;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        margin: 0 0 15px;
        line-height: 16px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-eventlocation {
    width: 100%;
    float: left;
    padding: 15px 0 0;
    border-top: 1px solid rgba(112, 112, 112, 0.18);

    span {
        width: 100%;
        float: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;

        i {
            font-size: 12px;
            color: #4472c4;
            line-height: 12px;
        }
    }
}

.up-eventcalendarholder {
    float: right;
    width: 320px;

    .up-themebox {
        padding: 20px;
        border: 1px solid rgba(112, 112, 112, 0.50);
    }
}

.up-createorganisationimage {
    width: 100%;
    float: left;
    max-width: 400px;
    margin: 0 0 30px;

    img {
        width: 100%;
        height: auto;
        display: block;
    }
}

.up-paddingminus,
.up-createorganisationpage {
    .up-loginformholder {
        padding: 45px 40px 60px;
    }
}

.up-formcreateorganisation {
    fieldset {
        .up-btn {
            width: 100%;
            float: left;
        }
    }
}

.up-createorganisationtwo {
    .up-loginformcontentholder {
        .up-formHead {
            h1 {
                font-size: 24px;
                margin: 0 0 30px;
                line-height: 26px;
                text-align: center;
            }
        }
    }
}

.up-uploadlogoarea {
    width: 100%;
    float: left;
    margin: 0 0 50px;
}

.up-uploadlogoimg {
    width: 115px;
    height: 115px;
    display: flex;
    margin: 0 auto;
    object-fit: cover;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 5px solid #4472c4;
}

.up-themeplaceholder {
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    object-fit: cover;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.up-cameraicon {
    left: 50%;
    top: -12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    color: var(--Malibu);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

    input[type=file] {
        display: none;
    }

    input[type=file]+label {
        margin: 0;
        width: 24px;
        height: 24px;
        display: flex;
        cursor: pointer;
        font-size: 12px;
        line-height: 24px;
        background: #fff;
        border-radius: 50%;
        align-items: center;
        color: var(--Malibu);
        justify-content: center;
    }
}

.up-createorganisationformtwo,
.up-createorganisationformthree {
    fieldset {
        .up-formbuttons {
            text-align: center;
        }
    }
}

.up-socialicon,
.up-socialiconsholder {
    width: 100%;
    float: left;
}

.up-socialicon {
    width: 100%;
    height: 45px;
    display: flex;
    padding: 10px 12px;
    border-radius: 8px;
    position: relative;
    align-items: center;
    border: 1px solid rgba(112, 112, 112, 0.25);

    i,
    input {
        display: inline-block;
        vertical-align: middle;
    }

    i {
        width: 28px;
        height: 28px;
        color: #fff;
        display: flex;
        font-size: 13px;
        border-radius: 50%;
        margin: 0 20px 0 0;
        align-items: center;
        background: #4472c4;
        justify-content: center;
    }

    input {
        border: 0 !important;
        padding: 0 !important;
        height: auto !important;
        width: calc(100% - 48px) !important;
    }
}

.up-socialicon+.up-socialicon {
    margin: 10px 0 0;
}

.up-organisationsettings {
    width: 100%;
    float: left;
    margin: 30px 0 0;

    .up-themeboxhead {
        h2 {
            float: left;
            font-size: 36px;
            line-height: 36px;
        }
    }
}

.up-organisationroles {
    width: 100%;
    float: left;

    h4 {
        width: 100%;
        float: left;
        font-size: 20px;
        color: #3f414d;
        margin: 0 0 10px;
        line-height: 22px;
        text-align: center;
    }

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        list-style: none;

        li {
            width: 100%;
            float: left;
            padding: 25px 0;
            list-style-type: none;
            border-bottom: 1px solid rgba(112, 112, 112, 0.24);

            .up-eventnameimgdate {
                .up-eventlogoimg {
                    width: 76px;
                    height: 76px;
                    padding: 6px;
                    border: 1px dashed #e4e4e4;
                }

                .up-eventnamedate {
                    h3 {
                        color: #000;
                        font-size: 14px;
                        line-height: 16px;
                    }

                    span {
                        display: block;
                        font-size: 12px;
                        color: #0f1588;
                        line-height: 14px;
                    }
                }
            }
        }
    }

    .up-usergroupsroles {
        .up-eventnameimgdate {
            justify-content: space-between;
        }

        .up-btneditrole {
            transform: none;
            position: static;

            button {
                color: #000;
            }

            .dropdown-menu {
                inset: initial !important;
                right: 30px !important;

                &:after {
                    display: none;
                }
            }
        }
    }
}

.up-organisationroles+.up-organisationroles {
    margin: 50px 0 0;

    h4 {
        margin: 0 0 30px;
    }
}

.up-usergroupsroles {
    width: 100%;
    float: left;

    .up-eventnameimgdate {
        width: 100%;
        padding: 12px 20px;
        border-radius: 8px;
        position: relative;
        background: rgba(90, 181, 223, 0.16);
        &:last-child{
            .up-btneditrole{
                .up-themedropdownmenu{
                    bottom: 0 !important;
                }
            }
        }

        .up-eventlogoimg {
            width: 46px;
            height: 46px;
        }

        .up-eventnamedate {
            h3 {
                margin: 0;
                color: #000;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .up-eventnameimgdate+.up-eventnameimgdate {
        margin: 12px 0 0;
    }
}

.up-openeditrolesbox {
    .up-eventnameimgdate {
        background: #4472c4;

        .up-eventnamedate {
            h3 {
                color: #fff;
            }
        }

        .up-btneditrole {
            color: #fff;
        }
    }
}

.up-editrolebox {
    width: 100%;
    float: left;
    padding: 25px;
    display: none;
    margin: 0 0 15px;
    background: #fff;
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    .up-eventnameimgdate {
        padding: 0;
        background: none;

        h3 {
            color: #000 !important;
        }
    }
}

.up-openeditrolesbox {
    .up-editrolebox {
        display: block;
    }
}

.up-btneditrole {
    top: 50%;
    z-index: 3;
    right: 20px;
    font-size: 14px;
    color: #4472c4;
    line-height: 16px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.up-editorganisationarea {
    width: 100%;
    float: left;
}

.up-editorganisationtabsarea {
    width: 100%;
    float: left;

    .up-themeboxhead {
        h3 {
            float: left;
            font-size: 36px;
            line-height: 36px;
        }
    }
}

.up-editorganisationhead {
    width: 100%;
    float: left;
    min-height: 60px;
    margin: 0 0 65px;
    padding: 10px 30px;
    background: #fff;

    .up-btn {
        float: right;
    }
}

.up-btneditogranisation {
    top: 10px;
    right: 15px;
    font-size: 14px;
    padding: 0 15px;
    color: #707070;
    line-height: 40px;
    position: absolute;
    background: #eff4fb;

    span {
        margin: 0 10px 0 0;
    }
}

.up-editogranisationtabs {
    width: 100%;
    float: left;
    margin: 25px 0 0;

    .nav {
        width: 100%;
        float: left;
        display: block;
        margin: 0 0 5px;
        border-bottom: 0;

        .nav-item {
            border: 0;
            float: left;
            color: #000;
            padding: 0 15px;
            font-size: 18px;
            font-weight: 600;
            background: none;
            min-width: 100px;
            line-height: 55px;
            text-align: center;
            border-radius: 8px;
            font-family: 'Montserrat', sans-serif;
        }

        .active {
            max-width: none;
            color: #fff !important;
            background: #4472c4 !important;
        }
    }
}

.up-organisationroleshead {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.up-btnaddnewrole {
    float: right;
    font-size: 14px;
    font-weight: 500;
    color: #0f1588;
    min-width: 130px;
    line-height: 45px;
    border-radius: 8px;
    border: 1px solid #0f1588;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;

    &:hover {
        color: #fff;
        background: var(--Malibu);
        border-color: var(--Malibu);
    }
}

.up-organisationrightarea {
    float: right;
}

.up-btncanceledit {
    float: right;
    margin: 10px 0 0;
}

/* =============================================
		Own Organisation Profile Page						
============================================= */
.up-ownorganisationprofilepage {
    width: 100%;
    float: left;

    .up-themebanner {
        min-height: 350px;
        max-height: 350px;
        position: relative;
        border-radius: 14px 14px 0 0;

        .up-bannerimg {
            right: auto;
            width: 100%;
            float: left;
            bottom: auto;
            height: 350px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }
    }
}

.up-changebannerbtn {
    top: 15px;
    left: 30px;
    width: 145px;
    height: 40px;
    position: absolute;

    input[type=file] {
        display: none;
    }

    input[type=file]+label {
        margin: 0;
        width: 100%;
        float: left;
        height: 40px;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
        line-height: 40px;
        background: red;
        text-align: center;
        border-radius: 6px;
        background: transparent;
        backdrop-filter: blur(30px);
        --webkit-backdrop-filter: blur(30px);
        border: 1px solid rgba(112, 112, 112, 0.16);
        background-color: rgba(255, 255, 255, 0.15);

    }
}

.up-uploadprofilepic {
    left: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
    position: absolute;

    input[type=file] {
        display: none;
    }

    input[type=file]+label {
        margin: 0;
        width: 40px;
        height: 40px;
        display: flex;
        cursor: pointer;
        font-size: 15px;
        color: #707070;
        line-height: 40px;
        border-radius: 50%;
        align-items: center;
        background: #eff4fb;
        justify-content: center;
    }
}

.up-createpostarea {
    width: 100%;
    float: left;
    overflow: hidden;
    margin: 0 0 35px;
    background: #fff;
    border-radius: 12px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

.up-createposthead {
    width: 100%;
    float: left;
    padding: 15px 35px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.18);

    h3 {
        margin: 0;
        font-size: 18px;
        line-height: 18px;
        color: rgba(112, 112, 112, 0.75);
    }
}

.up-createpost {
    width: 100%;
    float: left;
    padding: 15px 35px;
}

.up-organisationogo {
    margin: 0;
    float: left;
    width: 46px;
    height: 46px;
    display: flex;
    border-radius: 50%;
    margin: 0 35px 0 0;
    align-items: center;
    object-fit: contain;
    justify-content: center;
    border: 1px solid rgba(15, 21, 135, 0.5);

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-createpostholder {
    float: left;
    min-height: 120px;
    padding: 15px 0 0;
    width: calc(100% - 81px);

    p {
        margin: 0;
        cursor: pointer;
        font-size: 18px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.30);
    }
}

.up-formcreateupdates {
    fieldset {
        position: relative;

        .form-group {
            margin: 0;
            width: calc(100% - 81px);

            textarea {
                border: 0;
                width: 100%;
                float: left;
                padding: 10px 0;
                border-radius: 0;
            }
        }

        .up-btn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .up-organisationogo {
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .up-selectfilearea {
        width: auto;
        float: none;
        overflow: hidden;
        margin: 0 -25px 0;
        padding: 15px 20px;
    }
}

.up-selectfilearea {
    margin: 0;
    width: 100%;
    float: left;
    list-style: none;
    padding: 15px 35px;
    border-top: 1px solid rgba(112, 112, 112, 0.18);

    input[type=file] {
        display: none;
    }

    li {
        float: left;
        padding: 0 15px;
        list-style-type: none;

        &:first-child {
            padding-left: 0;
        }
    }
}

.up-uploadfiles {
    width: 100%;
    float: left;
    input[type=file]{
        display: none;
    }

    label {
        margin: 0;
        width: 100%;
        float: left;
        cursor: pointer;

        i,
        span {
            display: inline-block;
            vertical-align: middle;
        }

        span {
            font-size: 14px;
            line-height: 14px;
            color: var(--PaynesGrey);
        }

        i {
            font-size: 22px;
            line-height: 22px;
            margin: 0 15px 0 0;
            color: var(--Malibu);
        }
    }
}

.up-displaytoadvanceoption {
    overflow: hidden;
    margin: 0 -25px 0;
    padding: 15px 20px;
}

.up-displayto {
    float: left;

    em,
    span {
        display: inline-block;
        vertical-align: middle;
    }

    em {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        font-style: normal;
        margin: 0 15px 0 0;
        color: var(--PaynesGrey);
    }

    span {
        cursor: pointer;
        min-width: 90px;
        font-weight: 500;
        text-align: center;
        border-radius: 4px;
        color: var(--PaynesGrey);
        background: rgba(15, 21, 135, 0.16);

        img {
            width: 10px;
            margin: 0 0 0 10px;
        }
    }
}

.sr-only {
    display: none;
}

.up-customoption {
    cursor: pointer;
}

.up-forcustomshowaudience {
    fieldset {
        .up-btn {
            clear: both;
            margin: 0 auto;
            display: block;
            max-width: 245px;
        }
    }
}

.up-btnadvanceoptions {
    float: right;
    font-size: 14px;
    line-height: 16px;
    color: var(--PaynesGrey);

    img,
    span {
        display: inline-block;
    }

    img {
        width: 10px;
        height: auto;
        margin: 0 0 0 8px;
    }
}

.up-advanceoption,
.up-advanceoptions {
    width: 100%;
    float: left;
}

.up-advanceoptions {
    margin: 0 0 10px;
}

.up-advanceoption {
    margin: 10px 0;

    span {
        float: left;
        font-size: 14px;
        line-height: 14px;
        color: var(--PaynesGrey);
    }
}

.up-togglebutton {
    float: right;
    line-height: normal;

    input[type=checkbox] {
        display: none;

        &:disabled+label {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }

    input[type=checkbox]+label {
        width: 20px;
        height: 10px;
        cursor: pointer;
        position: relative;
        border-radius: 30px;
        margin: 0 !important;
        background: #707070;

        &:before {
            left: 0;
            top: -2px;
            content: '';
            width: 14px;
            height: 14px;
            position: absolute;
            border-radius: 50%;
            background: #707070;
        }
    }

    input[type=checkbox]:checked+label {
        background: var(--Malibu);

        &:before {
            right: 0;
            left: auto;
            background: #4472c4;
        }
    }
}

.up-chilsorganisation {
    .up-themeboxhead {
        h3 {
            margin: 0 0 25px;
        }

        span {
            display: block;
            color: #707070;
            ;
        }
    }
}

.up-btnaddorganisation {
    float: left;
    min-width: 80px;
    max-width: 80px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    background: #0f1588;
    color: #fff !important;

    span {
        float: none !important;
        width: auto !important;
        color: #fff !important;
    }
}

.up-ownorganisationmembers {
    margin: 50px 0 0;

    .up-themeboxhead {
        margin: 0 0 40px;

        h3 {
            float: left;
            font-size: 36px;
            line-height: 36px;
        }
    }

    .up-formallmembers {
        fieldset {
            .form-group {
                input {
                    box-shadow: none;
                    border: 1px solid rgba(112, 112, 112, 0.12);
                }
            }
        }
    }
}

.up-invitemembers {
    float: right;

    span {
        font-size: 14px;
        line-height: 16px;
        margin: 0 30px 0 0;
        display: inline-block;
        vertical-align: middle;
        color: var(--PaynesGrey);
    }
}

.up-btninvitemembers {
    border: 0;
    margin-bottom: 8px;
    color: #0f1588;
    background: rgba(91, 181, 223, 0.18);
}

.up-invitemembersmodal {
    .modal-dialog {
        max-width: 670px;
    }
}

.up-forminvitememners {
    fieldset {
        legend {
            font-size: 18px;
            line-height: 18px;
        }
    }
}

.up-formtwocols {
    clear: both;
    margin: 0 -12px 10px;

    &:after {
        clear: both;
        content: '';
        display: block;
    }
}

.up-inputsholder {
    float: left;
    margin: 0 12px;
    width: calc(50% - 24px);

    input {
        width: 100%;
        height: 45px;
        border-radius: 8px;
        border: 1px solid rgba(112, 112, 112, 0.25);
    }

    input+input {
        margin: 10px 0 0;
    }
}

.up-addmoreuploadcsv {
    width: 100%;
    float: left;
}

.up-btnatmore,
.up-uploadcsv {
    display: inline-block;
    vertical-align: middle;
}

.up-btnatmore {
    font-size: 12px;
    color: #0f1588;
    line-height: 12px;
    margin: 0 50px 0 0;
}

.up-uploadcsv {
    input[type=file] {
        display: none;
    }

    input[type=file]+label {
        color: #000;
        cursor: pointer;
        font-size: 12px;
        line-height: 12px;
        margin: 0 !important;
    }
}

.up-formbuttonsarea {
    width: 100%;
    float: left;
    margin: 45px 0 0;
}

.up-btnclosmodal {
    font-size: 14px;
    color: #707070;
    line-height: 14px;
    margin: 0 0 0 20px;
}

.up-themenodata {
    width: 100%;
    float: left;
    margin: 50px 0 30px;
}

.up-themenodatacontent {
    width: 100%;
    float: left;
    text-align: center;

    h4 {
        font-size: 36px;
        font-weight: 400;
        color: #707070;
        margin: 0 0 20px;
        line-height: 36px;
        font-family: 'Montserrat', sans-serif;
    }

    .up-btn {
        border: 0;
        color: #0f1588;
        background: rgba(90, 181, 223, 0.18);
    }
}

.up-tabsallgroups {
    .up-themeboxhead {
        margin: 0 0 40px;

        h3 {
            float: left;
            font-size: 36px;
            line-height: 36px;
        }
    }
}

.up-modalcreategroup {
    .modal-dialog {
        max-width: 985px;
    }
}

.up-creategroupcontentholder {
    width: 100%;
    float: left;
    padding: 50px 0 0;
}

.up-creategroupcontent {
    width: 50%;
    float: left;
    position: relative;
    padding: 0 40px 0 0;

    .up-membersarea {
        height: 240px;
        overflow: auto;
        margin: 15px 0 0;

        .up-member {
            .up-memberimg {
                width: 40px;
                height: 40px;
            }

            .up-membername {
                h4 {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        width: 0;
    }

    .up-formbuttonsarea {
        text-align: center;

        .up-btn {
            min-width: 245px;
        }
    }
}

.up-creategroupcontent+.up-creategroupcontent {
    padding: 0 0 0 40px;
}

.up-btnaddmember {
    top: 50%;
    right: 0;
    z-index: 2;
    font-size: 14px;
    color: #0f1588;
    line-height: 14px;
    position: absolute;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.up-gourpprofileimage {
    width: 100%;
    float: left;
    margin: 0 0 30px;
}

.up-groupprofileimg {
    margin: 0;
    width: 195px;
    height: 195px;
    margin: 0 auto;
    display: block;
    border-radius: 50%;
    border: 5px solid var(--Malibu);

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
    }
}

.up-uploadgroupimg {
    left: 50%;
    top: -25px;
    width: 45px;
    height: 45px;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);

    input[type=file] {
        display: none;
    }

    input[type=file]+label {
        width: 45px;
        height: 45px;
        display: flex;
        cursor: pointer;
        font-size: 18px;
        background: #fff;
        border-radius: 50%;
        align-items: center;
        color: var(--Malibu);
        margin: 0 !important;
        justify-content: center;
        -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    }
}

.up-allowuertochat {
    width: 100%;
    float: left;

    em {
        float: left;
        font-size: 14px;
        color: #707070;
        line-height: 16px;
        font-style: normal;
        font-family: 'Montserrat', sans-serif;
    }

    .up-select {
        width: 85px;
        float: right;

        &:after {
            line-height: 26px;
        }

        select {
            height: 26px;
            padding: 0 20px;
            font-size: 14px;
            border-radius: 5px;
            border-color: #0f1587;
            color: var(--PaynesGrey);
            text-transform: capitalize;
        }
    }
}

.up-addmorephotos {
    margin: 0;
    width: 100%;
    float: left;
    height: 210px;
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: var(--Malibu);

    input[type=file] {
        display: none;
    }

    input[type=file]+label {
        cursor: pointer;
        margin: 0 !important;

        i {
            width: 56px;
            height: 56px;
            color: #fff;
            margin: 0 auto;
            border-radius: 50%;
            align-items: center;
            display: inline-flex;
            justify-content: center;
            border: 1px solid rgba(112, 112, 112, 0.10);
            background: rgba(68, 114, 196, 0.10);
        }

        span {
            color: #fff;
            display: block;
            font-size: 14px;
            margin: 15px 0 0;
            line-height: 16px;
        }
    }
}

.up-filesarea,
.up-tablearea {
    width: 100%;
    float: left;
}

.up-filebox {
    width: 100%;
    float: left;
    padding: 30px 20px;
    border-radius: 10px;
    background: #eff4fb;
    border: 1px solid rgba(15, 21, 135, 0.16);
}

.up-filestable {
    margin: 0;
    border-spacing: 0 10px;
    border-collapse: separate;
}

.up-filestable thead tr th {
    font-size: 16px;
    text-align: left;
    color: #010e2a;
    font-weight: 400;
    line-height: 18px;
    padding: 0 15px 15px;
    border: 0 !important;
    vertical-align: middle;
    font-family: 'Montserrat', sans-serif;
}

.up-filestable thead tr th span {
    display: block;
}

.up-filestable tbody tr td {
    text-align: left;
    background: #fff;
    border: 0 !important;
    vertical-align: middle;
    padding: 10px 15px !important;

    &:first-child {
        border-radius: 10px 0 0 10px;
    }

    &:last-child {
        border-radius: 0 10px 10px 0;
        padding: 10px 25px 10px 15px !important;
    }
}

.up-filestable tbody tr td h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #676e7c;
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
}

.up-fileiconname {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;

    h4 {
        margin: 0;
        font-size: 14px;
        color: #010e2a;
        font-weight: 500;
        line-height: 16px;
    }
}

.up-fileicon {
    width: 35px;
    float: left;
    height: 35px;
    display: flex;
    margin: 0 20px 0 0;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
}

.up-bgproject {
    background: #16B95f;
}

.up-bgmicro {
    background: #8f58ff;
}

.up-bgprojectfile {
    background: #ff962d;
}

.up-bgpictures {
    background: #f3655f;
}

.up-btndropdownholder {
    width: 100%;
    float: left;

    .up-btn {
        color: #0f1588;
        font-weight: 400;
        background: rgba(15, 21, 136, 0.12);
    }

    .up-themedropdown {
        float: right;

        button {
            color: #002868;
        }
    }
}

.up-modalcreateevents {
    .modal-dialog {
        max-width: 700px;
    }
}

.up-createeventcontent {
    width: 100%;
    float: left;
    padding: 15px;

    .up-groupprofileimg {
        border: 0;
        width: 135px;
        height: 135px;
        background: #4472c4;

        .up-uploadgroupimg {
            top: 50%;
            -webkit-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            -o-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
        }
    }
}

.up-formcreateevent {
    .up-btn {
        clear: both;
        width: 245px;
        display: block;
        margin: 0 auto;
    }
}

.up-modalmemberdetail {
    .modal-dialog {
        max-width: 830px;
    }
}

.up-memberdetailholder {
    width: 100%;
    float: left;
    padding: 25px;
}

.up-membershortdetail {
    width: 100%;
    float: left;
    display: flex;
    margin: 0 0 50px;
    align-items: center;

    .up-userimage {
        width: 120px;
        height: 120px;
        margin: 0 40px 0 0;
    }
}

.up-usernameinfo {
    float: left;
    width: calc(100% - 160px);

    h3 {
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        font-family: 'Montserrat', sans-serif;
    }

    span {
        display: block;
        font-size: 18px;
        margin: 0 0 8px;
        color: #707070;
        line-height: 18px;
        font-family: 'Montserrat', sans-serif;
    }

    time {
        display: block;
        font-size: 14px;
        font-weight: 600;
        color: #4472c4;
        line-height: 14px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-membercontactdetail {
    margin: 0;
    width: 100%;
    float: left;
    font-size: 18px;
    font-weight: 600;
    list-style: none;
    padding: 0 0 20px;
    line-height: 18px;
    font-family: 'Montserrat', sans-serif;

    li {
        width: 50%;
        float: left;
        padding: 20px 0;
        min-height: 60px;
        line-height: inherit;
        list-style-type: none;

        h4 {
            margin: 0;
            float: left;
            color: #000;
            font-size: inherit;
            line-height: inherit;
            text-transform: none;
            width: calc(100% - 50px);
        }
    }
}

.up-contacticon {
    float: left;
    width: 50px;
}

.up-membertypeinfo {
    margin: 0;
    width: 100%;
    float: left;
    padding: 20px 0;
    list-style: none;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;

    li {
        width: 50%;
        float: left;
        padding: 25px 0;
        list-style: none;
        line-height: normal;

        h5 {
            font-size: 24px;
            color: #4472c4;
            font-weight: 600;
            line-height: 24px;
            font-family: 'Montserrat', sans-serif;
        }

        span {
            width: 100%;
            float: left;
            color: #000;
            font-size: 18px;
            font-weight: 600;
            line-height: 18px;
            font-family: 'Montserrat', sans-serif;
        }
    }
}

.up-memberdetailgroup {
    width: 100%;
    float: left;
    padding: 40px 0 0;

    .up-allgroups {
        margin: 0;

        h6 {
            font-size: 24px;
            color: #4472c4;
            font-weight: 600;
            line-height: 24px;
            font-family: "Montserrat", sans-serif;
        }

        .up-group {
            margin: 0;
            padding: 20px 0;
        }

        .up-group+.up-group {
            border-top: 1px solid rgba(112, 112, 112, 0.25);
        }
    }
}

/* =============================================
		All Files Page						
============================================= */
.up-allfilesarea {
    width: 100%;
    float: left;
    margin: 30px 0 0;
}

.up-searchfilesarea {
    width: 100%;
    float: left;
    margin: 0 0 40px;
}

.up-formsearchfiles {
    fieldset {
        position: relative;
        padding: 0 160px 0 0;

        .form-group {
            margin: 0 5px;
            width: calc(50% - 10px);

            input {
                height: 40px;
                border-radius: 30px;
            }
        }

        .up-btn {
            top: 0;
            right: 0;
            position: absolute;
            border-radius: 8px;
            background: #0f1588;

            i {
                font-size: 10px;
                line-height: 10px;
                margin: 0 10px 0 0;
            }
        }

        .css-1s2u09g-control {
            margin-top: 0 !important;

            .css-319lph-ValueContainer {
                padding: 0 8px !important;
            }
        }
    }
}

/* =============================================
		All Events Page						
============================================= */
.up-eventspage {
    width: 100%;
    float: left;

    .up-themebanner {
        margin: 0 0 30px;
    }

    .up-event {
        .up-eventhead {
            margin: 0;
        }
    }
}

.up-alleventsarea {
    width: 100%;
    float: left;
}

/* =============================================
		Users Groups Page						
============================================= */
.up-usersgroups {
    width: 100%;
    float: left;

    .up-themebanner {
        margin: 0 0 30px;
    }
}

.up-allusergroupsarea {
    width: 100%;
    float: left;
}

.up-groupstable {
    tbody {
        tr {
            td {
                padding: 12px 20px !important;

                .up-fileicon {
                    width: 58px;
                    height: 58px;
                    padding: 4px;
                    background: none;
                    border-radius: 50%;
                    border: 1px dashed #e4e4e4;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .up-btn {
                    border: 0;
                    float: right;
                }
            }
        }
    }
}

/* =============================================
		All Notifications Page Style				
============================================= */
.up-allnotificationspage {
    width: 100%;
    float: left;
}

.up-tablenotifications {
    tbody {
        tr {
            td {
                padding: 20px 15px !important;

                .up-fileicon {
                    width: 42px;
                    height: 42px;
                    margin: 0 10px 0 0;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }

                .up-fileiconname {
                    float: left;
                    display: block;
                    width: calc(100% - 52px);

                    p {
                        margin: 0;
                        font-size: 14px;
                        color: #707070;
                        line-height: 17px;
                    }
                }

                >span {
                    float: right;
                    color: #707070;
                }
            }
        }
    }
}

/* =============================================
		Chat Page Style				
============================================= */
.up-chatepage {
    width: 100%;
    float: left;
}

.up-chatsidebararea {
    float: left;
    width: 350px;
    height: calc(100vh - 140px);
    background: rgba(255, 255, 255, 0.50);
}

.up-chatsidebarhead {
    width: 100%;
    float: left;
    padding: 30px 15px 20px;

    h3 {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        line-height: 27px;
        color: var(--PaynesGrey);
    }
}

.up-chatcount {
    height: 22px;
    color: #fff;
    font-size: 12px;
    min-width: 22px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    background: #ff0000;
    display: inline-block;
    vertical-align: middle;
}

.up-formsearchchat {
    margin: 20px 0 0;

    fieldset {
        .form-group {
            margin: 0;
            position: relative;

            i {
                top: 50%;
                left: 15px;
                font-size: 12px;
                color: #e4e4e4;
                line-height: 12px;
                position: absolute;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }

            input {
                height: 35px;
                font-size: 12px;
                font-weight: 500;
                background: #fff;
                border-radius: 30px;
                color: var(--PaynesGrey);
                padding: 10px 20px 10px 40px;
                border: 1px solid rgba(112, 112, 112, 0.12);
            }

            .form-control::-webkit-input-placeholder {
                color: #e4e4e4;
            }

            /* WebKit, Blink, Edge */
            .form-control:-moz-placeholder {
                color: #e4e4e4;
            }

            /* Mozilla Firefox 4 to 18 */
            .form-control::-moz-placeholder {
                color: #e4e4e4;
            }

            /* Mozilla Firefox 19+ */
            .form-control:-ms-input-placeholder {
                color: #e4e4e4;
            }

            /* Internet Explorer 10-11 */
            .form-control::-ms-input-placeholder {
                color: #e4e4e4;
            }

            /* Microsoft Edge */
        }
    }
}

.up-chatusersholder {
    width: 100%;
    float: left;

    .up-chatusers {
        overflow: auto;
        height: calc(100vh - 300px);

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

.up-chatbuttons {
    padding: 0;
    width: 100%;
    float: left;
    list-style: none;
    margin: 0 !important;
    border: 0 !important;
    display: block !important;

    li {
        width: 50%;
        float: left;
        text-align: center;
        list-style-type: none;

        button {
            min-width: 65px;
            font-size: 14px;
            max-width: 110px;
            font-weight: 500;
            line-height: 14px;
            position: relative;
            text-align: center;
            padding: 0 10px 10px;
            border: 0 !important;
            display: inline-block;
            vertical-align: middle;
            color: #000 !important;
            background: none !important;

            &:before {
                left: 0;
                width: 0;
                bottom: 0;
                content: '';
                height: 2px;
                position: absolute;
                border-radius: 25px;
                background: #4472c4;
            }

            &:hover {
                &:before {
                    width: 100%;
                }
            }
        }

        .active {
            &:before {
                width: 100%;
            }
        }
    }
}

.up-chatusers {
    width: 100%;
    float: left;
    position: relative;

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        list-style: none;

        li {
            width: 100%;
            float: left;
            padding: 20px 15px;
            list-style-type: none;
        }

        li+li {
            border-top: 1px solid rgba(90, 181, 223, 0.16);
        }
    }
}

.up-chatuser {
    width: 100%;
    float: left;
    display: flex;
    cursor: pointer;
    align-items: center;

    .up-chatusersimg {
        border: 0;
        padding: 0;
        float: left;
        width: 42px;
        height: 42px;
        margin: 0 15px 0 0;
    }
}

.up-chatusercontent {
    float: left;
    width: calc(100% - 57px);

    .up-description {
        p {
            margin: 0;
            font-size: 12px;
            color: #707070;
            line-height: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-family: 'Montserrat', sans-serif;
        }
    }
}

.up-chatusercontenthead {
    width: 100%;
    float: left;
    margin: 0 0 5px;
    position: relative;

    h3 {
        margin: 0;
        float: left;
        color: #000;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        font-family: 'Montserrat', sans-serif;
    }

    time {
        float: right;
        font-size: 12px;
        color: #707070;
        line-height: 15px;
        font-family: 'Montserrat', sans-serif;
    }
}

.up-chatmessagearea {
    float: left;
    overflow: hidden;
    background: #fff;
    position: relative;
    padding: 0 0 100px;
    width: calc(100% - 350px);
    height: calc(100vh - 140px);
}

.up-nochatselected {
    float: left;
    padding: 30px;
    display: flex;
    overflow: hidden;
    background: #fff;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 350px);
    height: calc(100vh - 140px);

    h3 {
        margin: 0;
        font-size: 18px;
        color: #afafaf;
        line-height: 20px;
        font-family: 'Montserrat', sans-serif;
    }

    img {
        width: 35px;
        height: auto;
        display: block;
        margin: 0 0 15px;
    }
}

.up-chatheadarea {
    width: 100%;
    float: left;
    display: flex;
    position: relative;
    padding: 22px 30px;
    background: #fff;
    align-items: center;
    justify-content: space-between;
    -webkit-box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, 0.08);

    .up-chatuser {
        width: auto;
        display: flex;
        align-items: center;

        .up-chatusercontenthead {
            margin: 0;

            h3 {
                width: 100%;
                float: left;
            }

            span {
                display: block;
                font-size: 10px;
                font-weight: 600;
                color: #707070;
                line-height: 13px;
            }
        }
    }
}

.up-chatbtns {
    margin: 0;
    padding: 0;
    float: right;
    font-size: 18px;
    list-style: none;
    line-height: 18px;

    li {
        float: left;
        padding: 0 12px;
        line-height: inherit;
        list-style-type: none;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        button {
            display: block;
            line-height: inherit;
            color: var(--PaynesGrey);

            i,
            img {
                display: block;
            }
        }
    }
}

.up-chatmessagesholder {
    width: 100%;
    float: left;
    padding: 30px;
    overflow: auto;
    background: #fff;
    height: calc(100vh - 326px);

    &::-webkit-scrollbar {
        width: 0;
    }
}

.up-writemessagearea {
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    position: absolute;
    padding: 15px 20px 15px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.24);
}

.up-btnchatreaction {
    top: 50%;
    right: 35px;
    width: 30px;
    height: 30px;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    img,
    svg {
        width: 28px;
        height: 28px;
    }
}

.up-formwritemessages {
    fieldset {
        .form-group {
            margin: 0;
            width: 100%;
            float: left;
            position: relative;

            textarea {
                // position: relative !important;
                width: 100%;
                float: left;
                // color: #fff !important;
                // height: 55px !important;
                // background: #fff !important;
                border-radius: 35px;
                padding: 9px 100px 9px 9px !important;

                &::-webkit-scrollbar {
                    width: 0;
                }
                & ~ div{display: none;}
            }
        }
    }
}

.up-addbutton {
    // top: 50% !important;
    right: 95px !important;
    width: 28px !important;
    margin: 0 !important;
    height: 28px;
    z-index: 1;
    display: flex;
    color: #fff;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: var(--Malibu);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.up-btnsendmessage {
    top: 50%;
    right: 10px;
    height: 28px;
    width: 76px;
    display: flex;
    color: #fff;
    padding: 0 10px;
    font-size: 12px;
    line-height: 28px;
    position: absolute;
    text-align: center;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    background: var(--Malibu);
    text-transform: capitalize;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.up-message {
    width: 100%;
    float: left;
    clear: both;

    .up-userimg {
        float: left;
        width: 32px;
        height: 32px;
        margin: 0 30px 0 0;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}

.up-messagecontentholder {
    float: left;
    width: calc(100% - 62px);

    p {
        width: 100%;
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        color: var(--Malibu);
        font-family: 'Montserrat', sans-serif;
        
    }

    time {
        width: 100%;
        float: left;
        font-size: 12px;
        line-height: 14px;
        color: rgba(112, 112, 112, 0.75);
        font-family: 'Montserrat', sans-serif;
    }
}

.up-recievermessage {
    .up-userimg {
        float: right;
        margin: 0 0 0 30px;
    }

    .up-messagecontentholder {
        float: right;

        p {
            float: right;

            
        }

        time {
            text-align: right;
        }
    }

    .up-chatfilesholder {
        .up-chatfile {
            float: right;
        }
    }
}

.up-messageinfosidebar {
    top: 86px;
    width: 290px;
    right: -100%;
    padding: 40px 30px;
    position: absolute;
    background: #fff;
    height: calc(100vh - 226px);
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -webkit-box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.14);
    box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.14);
}

.up-opengroupinfo .up-gourpinfisidebar,
.up-openmembersidebar .up-memberssidebar,
.up-openmediasidebar .up-mediatabssidebar {
    right: 0;
}

.up-messageinfosidebarhead {
    width: 100%;
    float: left;
    margin: 0 0 30px;
}

.up-closesidebarinfo {
    float: left;
    color: #000;
    font-size: 10px;
    line-height: 10px;
    margin: 0 15px 0 0;
}

.up-infosidebarheadcontent {
    float: left;
    width: calc(100% - 25px);

    h3 {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        font-family: 'Montserrat', sans-serif;
    }

    span {
        padding: 0 4px;
        display: block;
        font-size: 12px;
        color: #707070;
        line-height: 12px;
        font-family: 'Poppins', sans-serif;
    }
}

.up-chatsidebarcontent {
    width: 100%;
    float: left;

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        list-style: none;

        li {
            width: 100%;
            float: left;
            display: flex;
            line-height: normal;
            align-items: center;
            list-style-type: none;
            justify-content: space-between;
        }

        li+li {
            margin: 16px 0 0;
        }
    }

    .up-membersarea {
        overflow: auto;
        height: calc(100vh - 345px);

        &::-webkit-scrollbar {
            width: 0;
        }

        .up-member {
            .up-memberimg {
                border: 0;
                padding: 0;
                width: 32px;
                height: 32px;
            }

            .up-membername {
                display: flex;
                align-items: center;
                width: calc(100% - 50px);
                justify-content: space-between;

                h4 {
                    margin: 0;
                    float: left;
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }
}

.up-btnsidebar {
    float: left;
    font-size: 14px;
    color: #707070;
    line-height: 14px;

    &:hover {
        color: var(--Malibu);
    }
}

.up-goupcount {
    width: auto;
    height: 25px;
    display: flex;
    min-width: 25px;
    font-size: 10px;
    font-weight: 600;
    border-radius: 50%;
    align-items: center;
    color: var(--Malibu);
    justify-content: center;
    background: rgba(197, 237, 255, 0.50);
}

.up-notificationwsitch {
    float: right;

    input[type=checkbox] {
        display: none;
    }

    input[type=checkbox]+label {
        margin: 0;
        padding: 0;
        width: 30px;
        height: 14px;
        cursor: pointer;
        position: relative;
        border-radius: 25px;
        background: #707070;

        &:before {
            top: 50%;
            left: 2px;
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            background: #e4e4e4;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    input[type=checkbox]:checked+label {
        background: var(--Malibu);

        &:before {
            left: auto;
            right: 2px;
            background: #edf9ff;
        }
    }
}

.up-memberssidebar {
    .up-messageinfosidebarhead {
        .up-infosidebarheadcontent {
            width: calc(100% - 40px);

            h3 {
                margin: 0;
                float: left;
            }

            .up-goupcount {
                float: right;
                margin: -5px 0 0;
                display: inline-flex;
                color: var(--Malibu);
            }
        }
    }
}

.up-countadmin {
    float: right;

    h5 {
        margin: 0;
        float: left;
        padding: 0 8px;
        font-size: 10px;
        color: #4472c4;
        line-height: 18px;
        text-align: center;
        border-radius: 25px;
        background: #d4e8f1;
        font-family: 'Montserrat', sans-serif;
    }

    h6 {
        margin: 0;
        float: left;
        font-size: 10px;
        font-weight: 600;
        line-height: 18px;
        padding: 0 0 0 10px;
        color: var(--PaynesGrey);
        font-family: 'Poppins', sans-serif;
    }
}

.up-mediatabssidebar {
    .up-messageinfosidebarhead {
        .up-infosidebarheadcontent {
            width: calc(100% - 40px);

            h3 {
                margin: 0;
                float: left;
            }

            .up-goupcount {
                float: right;
                margin: -5px 0 0;
                display: inline-flex;
                color: var(--Malibu);
            }
        }
    }
}

.up-mediasidebartabs {
    margin: 0 -30px;
    overflow: hidden;
    border: 0 !important;
    background: #e2f6ff;
    display: block !important;

    a {
        float: left;
        width: 33.33%;
        font-size: 14px;
        color: #707070;
        text-align: center;
        position: relative;
        margin: 0 !important;
        border: 0 !important;
        border-radius: 0 !important;
        font-family: 'Poppins', sans-serif;

        &:before {
            left: 0;
            width: 0;
            bottom: 0;
            content: '';
            height: 2px;
            position: absolute;
            border-radius: 25px;
            background: #4472c4;
        }

        &:hover {
            border: 0 !important;
            background: none !important;

            &:before {
                width: 100%;
            }
        }
    }

    .active {
        max-width: none !important;
        color: #4472c4 !important;
        background: none !important;

        &:before {
            width: 100%;
        }
    }
}

.up-allmediaholder {
    width: 100%;
    float: left;
    padding: 20px 0 0;
}

.up-allmedia {
    overflow: auto;
    margin: 0 -20px;
    height: calc(100vh - 404px);

    &::-webkit-scrollbar {
        width: 0;
    }

    time {
        width: 100%;
        float: left;
        font-size: 12px;
        margin: 0 0 15px;
        color: #707070;
        line-height: 12px;
        text-align: center;
    }
}

.up-chatmedias {
    z-index: 1;
    width: 100%;
    float: left;
    clear: both;
    display: grid;
    grid-gap: 5px;
    position: relative;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
}

.up-chatmediaimage {
    margin: 0;
    width: 100%;
    float: left;
    width: 60px;
    height: 60px;
    border-radius: 5px;

    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 5px;
    }
}

.up-searchgroupcreate {
    float: right;

    .up-formallmembers {
        border: 0;
        margin: 0;
        padding: 0;
        float: none;
        width: 380px;
        display: inline-block;
        vertical-align: middle;

        fieldset {
            .form-group {
                input {
                    box-shadow: none;
                    border-radius: 8px;
                    background: rgba(255, 255, 255, 0.50);
                }
            }
        }
    }

    .up-btn {
        margin: 0 0 0 10px;
    }
}

/* =============================================
		My Profile Page Style				
============================================= */
.up-bannerbottomarea {
    width: 100%;
    float: left;
    min-height: 130px;
    position: relative;
    background: #fff;
    padding: 0 80px 0 0;

    .up-profilenameimage {
        z-index: 9;
        left: auto;
        float: left;
        bottom: auto;
        position: static;
        margin: -70px 0 0;
        position: relative;
        padding: 0 0 0 80px;
    }

    .up-organisationprofileimg {
        border: 0;
        padding: 0;
        width: 174px;
        height: 174px;
        background: none;
    }

    .up-organisationname {
        padding: 90px 0 0;

        h3 {
            color: var(--PaynesGrey);
        }

        span {
            color: #707070;
        }
    }

    .up-btn {
        float: right;
        margin: 44px 0;
        cursor: pointer;
    }
}

.up-profilefollowingarea {
    float: left;
    width: calc(100% - 355px);
    & + .up-profilefollowingarea{margin: 30px 0 0;}
    h3 {
        font-size: 24px;
        margin: 0 0 40px;
        line-height: 24px;
        color: var(--PaynesGrey);
        font-family: 'Montserrat', sans-serif;
    }

    .up-tablearea {
        .up-groupstable {
            margin: 0;

            tbody {
                tr {
                    td {
                        .up-organisermetadata {
                            margin: 0;
                        }

                        .up-btn {
                            background: #fff;
                            color: var(--Malibu);
                            text-transform: uppercase;
                            border: 1px solid var(--Malibu);
                        }

                        .up-fileiconname {
                            display: block;

                            h4 {
                                overflow: hidden;
                            }

                            em {
                                overflow: hidden;
                                font-style: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}

.up-editprofilearea,
.up-iconcontentholder {
    width: 100%;
    float: left;
    position: relative;
}

.up-editprofilearea {
    h3 {
        float: left;
    }

    .up-btn {
        float: right;
    }
}

.up-iconcontentholder {
    height: 46px;
    border-radius: 8px;
    padding: 10px 125px 10px 60px;
    border: 1px solid rgba(112, 112, 112, 0.24);

    em {
        width: 100%;
        float: left;
        height: 34px;
        color: #000;
        overflow: auto;
        font-size: 14px;
        font-weight: 500;
        max-width: 500px;
        line-height: 20px;
        font-style: normal;
        padding: 0 !important;
        width: 100% !important;
        outline: none !important;
        white-space: nowrap !important;
        font-family: 'Montserrat', sans-serif;

        &::-webkit-scrollbar {
            width: 0;
        }

        em {
            width: 100%;
            float: left;
            height: 34px;
            color: #000;
            overflow: auto;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            font-style: normal;
            max-width: 500px;
            padding: 0 !important;
            width: 100% !important;
            outline: none !important;
            white-space: nowrap !important;
            font-family: 'Montserrat', sans-serif;

            &::-webkit-scrollbar {
                width: 0;
            }
        }
    }
}

.up-profileicon {
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    display: flex;
    z-index: 2;
    position: absolute;
    align-items: center;
    background: #0f1588;
    justify-content: center;
    border-radius: 8px 0 0 8px;
    border: 1px solid rgba(112, 112, 112, 0.24);

    i {
        left: 0;
        top: -12px;
        z-index: 1;
        opacity: 0;
        color: #fff;
        padding: 0 5px;
        font-size: 14px;
        min-width: 100px;
        line-height: 25px;
        visibility: hidden;
        position: absolute;
        font-style: normal;
        border-radius: 5px;
        background: #7c7676;
        -webkit-transition: all 300ms ease-in-out;
        -moz-transition: all 300ms ease-in-out;
        -ms-transition: all 300ms ease-in-out;
        -o-transition: all 300ms ease-in-out;
        transition: all 300ms ease-in-out;
    }

    &:hover {
        i {
            top: -25px;
            opacity: 1;
            visibility: visible;
        }
    }
    img{
        width: 28px;
        height: auto;
        display: block;
    }
}

.up-saveeditbtns {
    top: 50%;
    margin: 0;
    z-index: 2;
    padding: 0;
    right: 10px;
    list-style: none;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);

    li {
        padding: 0 6px;
        line-height: normal;
        display: inline-block;
        list-style-type: none;
        vertical-align: middle;

        button {
            display: block;
            font-size: 14px;
            color: #ff0000;
            line-height: 26px;
        }

        .up-btnsave {
            padding: 0 10px;
            font-weight: 700;
            color: #6bdd1f;
            border-radius: 4px;
            font-family: 'Montserrat', sans-serif;
            background: rgba(107, 221, 31, 0.14);
        }

        .up-editbtnpencil {
            i {
                color: #707070;
            }
        }
    }
}

.up-editprofileinputs {
    clear: both;
    margin: 0 -22px;
}

.up-editprofileinputs:after {
    clear: both;
    content: '';
    display: block;
}

.up-editprofileinputs .form-group {
    float: left !important;
    margin: 0 22px 15px !important;
    width: calc(50% - 44px) !important;
}

.up-confirmbuttonsholder {
    label {
        margin: 0;
        width: 40px;
        color: #fff;
        height: 40px;
        cursor: pointer;
        border-radius: 50%;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        background: var(--Malibu);
    }

    label+label {
        margin: 0 0 0 10px;
    }
}

.up-changeprofilebtns {
    .up-checkbutton {
        bottom: 0;
        margin: 0;
        color: #fff;
        width: 40px;
        height: 40px;
        right: -100px;
        display: flex;
        cursor: pointer;
        font-size: 15px;
        line-height: 40px;
        border-radius: 50%;
        position: absolute;
        align-items: center;
        justify-content: center;
        background: var(--Malibu);
    }
}

.up-previewimagesarea {
    width: 100%;
    float: left;
    overflow: auto;
    padding: 0 0 20px;
    white-space: nowrap;

    >div {
        clear: both;
        height: 120px;
        min-width: 150px;
        max-width: 150px;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        display: inline-block;
        vertical-align: middle;
        border: 2px solid var(--Malibu);

        img,
        video {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }

    >div+div {
        margin: 0 0 0 20px;
    }

    label {
        float: left;
        clear: both;
        margin: 0 15px 0 0;
    }
}

.up-btndelete {
    top: 5px;
    right: 5px;
    z-index: 2;
    width: 25px;
    height: 25px;
    color: #fff;
    display: flex;
    font-size: 12px;
    background: red;
    border-radius: 50%;
    position: absolute;
    align-items: center;
    justify-content: center;
}

.up-selectimagvideoarea {
    width: 100%;
    float: left;
}

.up-filepreiview {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: inline-flex !important;

    .icon-file {
        font-size: 34px;
        margin: 0 0 15px;
        color: #c3c3c3;
        line-height: 34px;
    }

    span {
        display: block;
        font-size: 12px;
        line-height: 14px;
        color: var(--Malibu);
        font-family: 'Montserrat', sans-serif;
    }
}

.up-profiledescriptionarea {
    width: 100%;
    float: left;
    margin: 25px 0 0;

    .form-group {
        label {
            width: 100%;
            float: left;
            color: #000;
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 20px;
            line-height: 20px;
            font-family: 'Montserrat', sans-serif;
        }
    }
}

.up-editsocialmediainouts {
    label {
        margin: 0;
        width: 100%;
        float: left;
        color: #000;
        font-size: 18px;
        font-weight: 600;
        margin: 0 22px 20px;
        line-height: 20px;
        font-family: 'Montserrat', sans-serif;
    }

    .form-group {
        .up-iconcontentholder {
            border: 0;
            padding: 0;
            height: auto;

            .up-profileicon {
                top: 50%;
                left: 20px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);

                img {
                    width: 5px;
                }
            }

            .up-instagramicon {
                img {
                    width: 10px;
                }
            }

            input {
                height: 45px;
                border-radius: 8px;
                padding: 10px 20px 10px 55px;
            }
        }
    }
}

.up-errormessage {
    .up-iconcontentholder {
        .form-text {
            top: 50%;
            margin: 0;
            right: 25px;
            position: absolute;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }
}

.up-memberprofilright {
    float: right;
    padding: 44px 0;

    .up-btn {
        margin: 0;
        float: left;
    }
}

.up-socialmedias {
    padding: 0;
    float: left;
    list-style: none;
    margin: 0 15px 0 0;

    li {
        float: left;
        padding: 0 5px;
        list-style-type: none;
    }
}

.up-btnsocialmedia {
    width: 40px;
    height: 40px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    background: red;
    border-radius: 50%;
}

.up-btntwitter {
    background: #5ab5df;
}

.up-btnpintrest {
    background: #e90000;
}

.up-btnfacebook {
    background: #4472c4;
}

.up-btninstagram {
    background: #dd2a7b;
}

.up-profileiconinputholder {
    width: 100%;
    float: left;
    position: relative;

    input {
        padding: 10px 10px 10px 60px;
        // height: 43px !important;
    }
    .css-1d8n9bt,
    .css-319lph-ValueContainer{
        padding: 0px 8px 0 60px;
    }
    .css-nwjfc,
    .css-14dclt2-Input{
        margin: 0 !important;
        padding: 0 !important;
    }
}

.up-formeditprofile {
    .up-formbuttons {
        margin: 25px 0 0;

        .up-btn+.up-btn {
            background-color: var(--Malibu);
            margin: 0 10px 0 0;

            &:hover {
                background: #3c93bb;
            }
        }
    }
}

/* =============================================
		Terms Conditions Page Style				
============================================= */
.up-termandconditionpage {
    width: 100%;
    float: left;

    .up-themebox {
        h1 {
            font-size: 28px;
            font-weight: 500;
            margin: 0 0 25px;
            line-height: 30px;
            font-family: 'Montserrat', sans-serif;
        }
    }
}

/* LOADER STYLE */
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid var(--Malibu);
    border-color: var(--Malibu) transparent var(--Malibu) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.up-async-select .css-1s2u09g-control {
    width: 100% !important;
    border-radius: 30px !important;
}

.up-async-select .css-1pahdxg-control {
    width: 100% !important;
    border-radius: 30px !important;
}
.up-async-select>div>div:first-child{
    padding: 2px 82px 2px 50px !important;
}
.up-async-select>div>div>div {
    margin: 0 !important;

    input {
        height: 30px !important;
    }
}
.up-async-select .css-319lph-ValueContainer {
    padding: 2px 62px 2px 50px !important;
}

.css-1s2u09g-control:hover {
    border-color: hsl(0, 0%, 70%) !important;
}
.up-searchmenuholder > div:nth-child(2){
    overflow: hidden;
    height: 100px;
    & > div{
        height: 100px !important;   
    }
}
.up-searchmenuholder > div:first-child{
    overflow: hidden;
    // height: 100px;
    & > div{
        height: auto !important;
    }
}
//   Full screen Loader
.up-fullloader {
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    background: #fff;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.80);
}

.loader {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    perspective: 1000px;
    color: var(--Malibu);
    transform: rotateZ(45deg);

    &:before,
    &:after {
        top: 0;
        left: 0;
        content: '';
        width: inherit;
        display: block;
        height: inherit;
        position: absolute;
        border-radius: 50%;
        transform: rotateX(70deg);
        animation: 1s spin linear infinite;
    }

    &:after {
        color: var(--Malibu);
        transform: rotateY(70deg);
        animation-delay: .4s;
    }
}

@keyframes rotate {
    0% {
        transform: translate(-50%, -50%) rotateZ(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotateZ(360deg);
    }
}

@keyframes rotateccw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes spin {

    0%,
    100% {
        box-shadow: .3em 0px 0 0px currentcolor;
    }

    12% {
        box-shadow: .3em .3em 0 0 currentcolor;
    }

    25% {
        box-shadow: 0 .3em 0 0px currentcolor;
    }

    37% {
        box-shadow: -.3em .3em 0 0 currentcolor;
    }

    50% {
        box-shadow: -.3em 0 0 0 currentcolor;
    }

    62% {
        box-shadow: -.3em -.3em 0 0 currentcolor;
    }

    75% {
        box-shadow: 0px -.3em 0 0 currentcolor;
    }

    87% {
        box-shadow: .3em -.3em 0 0 currentcolor;
    }
}

.up-smallloader {
    z-index: 9;
    position: absolute;
    border-radius: 12px;
}

.infinite-scroll-component {
    overflow: inherit !important;
}

.up-btnfollowrequestsend {
    color: #fff !important;
    background: var(--Malibu) !important;
}

.up-assignnewrolemodal {
    .up-formdisplayto {
        fieldset {
            ul {
                padding: 0;
                width: 100%;
                float: left;
                list-style: none;

                li {
                    width: 100%;
                    float: left;
                    list-style-type: none;
                }
            }
        }
    }
}

.react-calendar .react-calendar__tile:enabled:hover,
.react-calendar .react-calendar__tile:enabled:focus {
    color: #fff;
    abbr {
        background: #5ab5df;
        color: #fff;
    }
}

iframe {
    display: none !important;
    width: 0px !important;
    height: 0px !important;
}

.up-usergrouproleseditholder {
    width: 100%;
    float: left;

    &:nth-child(even) {
        margin: 10px 0 0;
    }
}

.up-usergrouproleseditholder+.up-usergrouproleseditholder {}

.cropper {
    height: 300px;
    position: relative;
}

.slider {
    height: 10%;
    display: flex;
    align-items: center;
    margin: auto;
    width: 60%;
}

.up-allpostseen {
    width: 100%;
    float: left;
    margin: 15px 0
}

.up-margintop {
    margin: 30px 0 0;
}

.up-member {
    &:last-child {
        .up-membername {
            .up-themedropdown {
                .up-themedropdownmenu {
                    top: auto !important;
                    bottom: 0 !important;
                    right: 10px !important;
                }
            }
        }
    }
    &:first-child{
        .up-membername {
            .up-themedropdown {
                .up-themedropdownmenu {
                    top: 0 !important;
                    bottom: auto !important;
                }
            }
        }
    }
    // &:nth-child(2){
    //     .up-membername {
    //         .up-themedropdown {
    //             .up-themedropdownmenu {
    //                 top: 0 !important;
    //                 bottom: auto !important;
    //             }
    //         }
    //     }
    // }
}

.up-padding-top-zero {
    padding-top: 0 !important;

    input {
        border: 0 !important;
        height: 43px !important;
    }
}

// Dashboard style start sajid

.up-dashboard {
    float: left;
    width: 100%;
}

.up-stats {
    width: 100%;
    float: left;
    padding: 0 0 30px;
}

.up-statsgrid {
    clear: both;
    display: grid;
    margin: 30px 0;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

}
.up-stat {
    width: 100%;
    float: left;
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.15);
    box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.15);

    .up-staticon {
        width: 80px;
        height: 80px;
        float: left;
        margin: 0 24px 0 0;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .up-statcontent {
        float: left;
        width: calc(100% - 104px);

        ul {
            margin: 0;
            padding: 0;
            width: 100%;
            float: left;
            list-style: none;

            li {
                float: left;
                list-style-type: none;

                span {
                    display: block;
                    font-size: 16px;
                    color: #828282;
                    font-weight: 400;
                    line-height: 20px;
                    text-transform: capitalize;
                }

                h3 {
                    margin: 0;
                    color: #333;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 36px;
                }

                &:nth-child(2) {
                    float: right;

                    span {
                        color: #6FCF97;
                    }
                }
            }
        }
    }
}

.up-dashboradcontentholder {
    width: 100%;
    float: left;
}

.up-dashboradcontent {
    clear: both;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(410px, 1fr));
}

.up-dashboarddata {
    width: 100%;
    float: left;
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.15);
    box-shadow: inset 0 0 15px 0 rgba(255, 255, 255, 0.15);

    .up-dashboardsectiontitle {
        width: 100%;
        float: left;
        padding: 0 0 32px;

        h3 {
            margin: 0;
            color: #333;
            font-size: 24px;
            font-weight: 600;
            line-height: 27px;
            text-transform: capitalize;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        overflow: auto;
        list-style: none;
        height: calc(100vh - 440px);

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            width: 100%;
            float: left;
            list-style-type: none;
            padding: 12px 0;

            &:first-child {
                padding: 0 0 12px;
            }

            &:last-child {
                padding: 12px 0 0;
            }
            .up-user{
                padding: 12px 0;
                &:first-child {
                    padding: 0 0 12px;
                }
    
                &:last-child {
                    padding: 12px 0 0;
                }
            }
        }
    }
}

.up-user {
    width: 100%;
    float: left;

    figure {
        width: 60px;
        height: 60px;
        float: left;
        margin: 0 16px 0 0;

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    div {
        float: left;
        text-align: left;
        padding: 6px 0;
        width: calc(100% - 76px);

        h4 {
            margin: 0;
            color: #333;
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
        }

        a {
            display: block;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: #00A5D7;
        }
    }
}


// users page style start
.up-usersholer {
    width: 100%;
    float: left;
}

.up-userfilterholder {
    width: 100%;
    float: left;
    padding: 0 0 16px;

    .up-userspagetitle {
        float: left;
        width: auto;
        h2 {
            margin: 0;
            font-size: 32px;
            font-weight: 600;
            color: #252733;
            line-height: 35px;
            text-transform: capitalize;
        }
    }

    .up-formtheme {
        // width: calc(100% - 170px);
        width: auto;
        float: left;
        padding: 0 0 0 20px;

        button,
        .up-select,
        .form-group {
            float: left;
            margin: 0 6px 0 0;
        }

        .up-select,
        .form-group {
            width: 170px;

            .up-inputwithicon {
                input {
                    padding: 10px 10px 10px 30px;
                    height: 42px;
                }

                i {
                    top: 50%;
                    left: 10px;
                    width: 16px;
                    height: 16px;
                    color: #333;
                    font-size: 16px;
                    line-height: 16px;
                    position: absolute;
                    -moz-transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
        }

        .up-select {
            select {
                height: 42px;
                border-radius: 8px;
            }
        }

        .up-filterbtn {
            float: right;

            .up-btnfilter {
                margin: 0;
                display: block;
                padding: 0 10px;
                font-size: 16px;
                line-height: 43px;
                border-radius: 4px;
                border: 1px solid #E6E6E6;
            }
        }
    }
}

.up-userstable {
    width: 100%;
    float: left;

    table {
        thead {
            th {
                border: 0;
                padding: 12px;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: #7D829E;
                text-align: left;
            }
        }

        tbody {
            td {
                border: 0;
                padding: 12px;
                font-size: 16px;
                line-height: 16px;
                font-weight: 400;
                color: #252733;
                text-align: left;
                // display: inline-block;
                vertical-align: middle;

                .up-usertabledetail {
                    width: 100%;
                    float: left;

                    figure {
                        width: 44px;
                        height: 44px;
                        float: left;
                        margin: 0 24px 0 0;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    h4 {
                        float: left;
                        margin: 14px 0;
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 600;
                        color: #252733;
                        text-align: left;
                    }
                }
            }
        }

        tr {
            border-bottom: 1px solid #DFE0EB;
        }
    }

    .up-paginationholder {
        float: right;

        .up-pagination {
            margin: 0;
            padding: 0;
            width: 100%;
            float: left;
            list-style: none;

            li {
                display: inline-block;
                list-style-type: none;
                vertical-align: middle;
                padding: 0 0 0 8px;

                button {
                    padding: 0;
                    line-height: 25px;
                    width: 25px;
                    border-radius: 50%;
                }

                p {
                    margin: 0;
                }
            }
        }
    }
}

// Event style start
.up-eventspageholder {
    width: 100%;
    float: left;
}

.up-eventtwocol {
    width: 100%;
    float: left;
}

.up-eventcolone {
    float: left;
    width: calc(100% - 520px);
}

.up-eventholder {
    float: left;
    width: 100%;
    padding: 30px;
    margin: 0 0 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 10px 20px rgba(9, 39, 116, 0.05);
}

.up-eventuserhead {
    width: 100%;
    float: left;
    padding: 0 0 24px;

    figure {
        width: 56px;
        float: left;
        height: 56px;
        margin: 0 14px 0 0;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    div {
        float: left;
        padding: 12px 0;
        text-align: left;
        width: calc(100% - 100px);

        h3 {
            margin: 0;
            font-size: 14px;
            color: #828282;
            font-weight: 400;
            line-height: 17px;
        }

        span {
            display: block;
            font-size: 11px;
            font-weight: 400;
            color: #828282;
            line-height: 14px;
        }
    }

    .up-iconmenuinevent {
        float: right;
        padding: 18px 0;
        font-size: 18px;
        color: #4F4F4F;
        line-height: 18px;
    }
}

.up-eventbody {
    width: 100%;
    float: left;
}

.up-eventdetil {
    width: 100%;
    float: left;
    padding: 20px;
    margin: 0 0 24px;
    border-radius: 6px;
    background: #f2f2f2;
}

.up-eventnamedescription {
    width: 100%;
    float: left;
    padding: 0 0 16px;

    div {
        width: 100%;
        float: left;
        padding: 0 0 12px;

        h3 {
            float: left;
            color: #333;
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
            margin: 0 6px 0 0;
            text-transform: capitalize;
        }

        span {
            float: left;
            padding: 2px 0;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: #828282;
        }
    }

    a {
        display: block;
        color: #00A5D7;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
}

.up-eventdescription {
    width: 100%;
    float: left;
    text-align: left;

    .up-description {
        p {
            margin: 0 0 20px;
            text-align: left;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: #828282;

            &:last-child {
                margin: 0;
            }
        }
    }
}

.up-eventattendees {
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    list-style: none;

    li {
        float: left;
        list-style-type: none;

        span {
            display: block;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: #4f4f4f;
        }

        &:last-child {
            float: right;
        }
    }
}

.up-eventcoltwo {
    width: 490px;
    float: right;
}

.up-postdescription {
    p {
        margin: 0;
        color: #333;
        font-size: 14px;
        text-align: left;
        line-height: 17px;
        font-weight: 400;
    }
}

.up-eventsupdatesimageholder {
    width: 100%;
    float: left;
    padding: 0 0 24px;

    figure {
        float: left;
        height: 330px;
        margin: 0 1px 0 0;
        width: calc(50% - 1px);

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 6px;
            object-fit: cover;
        }

        &+figure {
            margin: 0 0 0 1px;
        }
    }
}

.up-postudatestopevents {
    .up-dashboarddata {
        margin: 0 0 30px;

        &:last-child {
            margin: 0;
        }

        ul {
            height: calc(100vh - 730px);
        }
    }

    .up-dashboardsectiontitle {
        h3 {
            float: left;
            text-align: left;
        }

        a {
            float: right;
            color: #00A5D7;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }
    }
}

.up-profileholder {
    width: 100%;
    float: left;

    .up-profilecontent {
        max-width: 490px;
        margin: 64px auto 0;
        overflow: hidden;

        .up-profileimageupdate {
            width: 100%;
            float: left;
            padding: 0 0 48px;
            text-align: center;

            figure {
                width: 140px;
                height: 140px;
                margin: 0 0 16px;
                position: relative;
                display: inline-block;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                    border-radius: 50%;
                }

                figcaption {
                    bottom: 20px;
                    right: -10px;
                    position: absolute;

                    input[type=file] {
                        display: none;
                    }

                    label {
                        margin: 0;
                        width: 30px;
                        color: #fff;
                        height: 30px;
                        font-size: 14px;
                        cursor: pointer;
                        line-height: 30px;
                        border-radius: 50%;
                        background: #00A5D7;
                        border: 1px solid #fff;
                    }
                }
            }

            h3 {
                margin: 0;
                color: #292B29;
                font-size: 24px;
                line-height: 24px;
                font-weight: 600;
                text-transform: capitalize;
            }
        }

        .form-group {
            background: #fff;
            border-radius: 8px;
            position: relative;
            padding: 10px 24px;

            label {
                margin: 0;
            }

            input {
                border: 0;
                padding: 0;
                height: 30px;
                background: none;
            }
        }
        .up-btnholder {
            width: 100%;
            float: right;
            text-align: right;

            .up-btncancel {
                color: #00A5D7;
                background: none;
                margin: 0 12px 0 0;
            }
        }
    }
}

.up-btnmodalchangepassword {
    top: 50%;
    padding: 0 !important;
    right: 20px;
    color: #00A5D7 !important;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    position: absolute;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    &:hover,
    &:focus {
        padding: 0;
        color: #00A5D7 !important;
        box-shadow: none !important;
    }
}

.up-changepasswordmodal {
    .modal-content {
        padding: 32px;
        border: 0;
        border-radius: 8px;

        .modal-header {
            border: 0;
            width: 100%;
            display: block;
            padding: 0 0 24px;
            text-align: center;

            .modal-title {
                margin: 0;
                color: #333;
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
            }

            span {
                color: #828282;
                display: block;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                padding: 8px 0 0;
            }

            .close {
                top: 32px;
                right: 32px;
                color: #333;
                font-size: 16px;
                line-height: 16px;
                position: absolute;

                span {
                    padding: 0;
                    font-size: 26px;
                }
            }

            .sr-only {
                display: none;
            }
        }

        .modal-body {
            padding: 0;

            .up-btn {
                color: #fff;
                padding: 0 25px;
                position: relative;
                text-align: center;
                border-radius: 8px;
                display: inline-block;
                vertical-align: middle;
                background: var(--Malibu);
                border: 1px solid #dbdbdb;
                font: 400 14px/40px 'Montserrat', Arial, Helvetica, sans-serif;
            }
        }
    }
}
.up-usertableholder{
    width: 100%;
    float: left;
    min-height: calc(100vh - 200px);
}
.up-wrappertwo{padding: 100px 30px 30px 250px;}
.up-headerdashboard{
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    h2{
        margin: 0;
        font-size: 28px;
        line-height: 30px;
        font-weight: 500;
        color: #000;
    }
    .up-menuiconholder {
        display: none;
        width: 70px;
        position: absolute;
        height: 44px;
        line-height: 44px;
        text-align: center;
        z-index: 11;
        top: 1px;
        left: 0;
    }
}
.up-sidebardashboard{
    top: 0;
    z-index: 10;
    width: 220px;
    height: 100vh;
    padding: 40px 0 0;
    background: #0f1587;
    .navigation{width: 100%;}
    .up-menuiconholder {
        display: none;
        width: 70px;
        position: absolute;
        height: 44px;
        line-height: 44px;
        text-align: center;
        z-index: 11;
        top: 0;
        right: 0;
    }
    .list-item{padding: 16px 12px;}
    .list-item a{
        padding: 8px;
        border-radius: 6px;
        max-width : 100% !important;
    }
    .list-item a.up-active {background: #00A5D7;}
    .list-item a img{float: left;}
    .list-item a em{
        position: static;
    }
    .up-menuiconholder{
        display: none;
    }
}
.up-usergroupdetail{
    width: 100%;
    float: left;
    .up-organisercard{
        margin: 0 0 30px;
        .up-organisercoverimg{
            height: 260px;
        }
    }
}
.react-datepicker__input-container{
    input{height: 45px;}
}
.up-datapickerfilterarea{
    .react-datepicker__input-container{
        input{
            width: 100%;
            border-radius: 30px;
        }
    }
}
.up-bgwhite{
    background: #fff;
    border-radius: 8px;
    &.up-statsgrid{
        grid-gap: 0;
    }
}
.up-flexbox{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.up-setaligndatetime{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    // input{
    //     width: 25% !important;
    // }
}
.up-paddingleft{
    cursor: pointer;
    display: inline-block !important;
    padding: 0 0 0 10px;
}
.up-selectedoptiongroup{
    width: 100%;
    float: left;
    padding: 0 0 12px;
    .up-selecedtoption{
        width: 100%;
        float: left;
        position: relative;
        z-index: 2;
        padding: 5px 8px;
        margin: 4px 0;
        &.up-topvoted{
            span{font-weight: 700;}
            em{background: #5ab5df;}
        }
        span{
            float: right;
            &.up-pollclosed{float: left;}
        }
        em{
            top: 0;
            left: 0;
            z-index: -1;
            height: 100%;
            line-height: 34px;
            padding: 0;
            font-weight: 700;
            text-align: right;
            font-style: normal;
            background: #c3c3c3;
            border-radius: 8px;
            position: absolute;
        }
    }
}
.rt-selectoptionstyle{
    width: 100%;
    float: left;
    z-index: 1;
    padding: 6px 0;
    position: relative;
    .up-selectedoptiongroup{
        top: 0;
        left: 0;
        z-index: -1;
        height: 100%;
        position: absolute;
        .up-selecedtoption{height: 100%;}
    }
}
.up-allreadycreatedpoll{
    border: 2px solid #00A5D7;
    border-radius: 8px;
    
}
.up-selectedoptiongroupposition{
    width: 100%;
    float: left;
    padding: 0;
    .up-selecedtoption{
        width: 100%;
        float: left;
        position: relative;
        z-index: 2;
        padding: 5px 8px;
        margin: 0;
        &.up-topvoted{
            span{font-weight: 700;}
            em{background: #5ab5df;}
        }
        span{
            float: right;
            &.up-pollclosed{float: left;}
        }
        em{
            top: 0;
            left: 0;
            z-index: -1;
            height: 100%;
            line-height: 34px;
            padding: 0;
            font-weight: 700;
            text-align: right;
            font-style: normal;
            background: #c3c3c3;
            border-radius: 8px;
            position: absolute;
        }
    }
}
.up-usergroupupdate{
    float: left;
    padding: 20px 0 20px 50px;
    h3{
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #000;
    }
}
.hidden{
    display: none;
}
.up-btnlikeunlike{
    float: left;
    width: 20px;
    margin: -2px 0 0 30px;
}
.up-active a{background: #00A5D7;}

.up-btn{
    .lds-dual-ring {
        width: 40px;
        height: 40px;
        &::after{
            width: 24px;
            height: 24px;
        }
    }
}
.up-eventlocationinput{
    .css-1hwfws3{height: 45px;}
}
.up-showmemderinevent{
    .up-profiletabs{
        margin: 0 0 16px;
        a{
            width: 33.33%;
            text-align: center;
        }
    }
}
.up-minheight{
    display: flex;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}
.up-eventuserdetail {
    width: 100%;
    float: left;

    figure {
        width: 44px;
        height: 44px;
        float: left;
        margin: 0 24px 0 0;

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    h4 {
        float: left;
        margin: 14px 0;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        color: #252733;
        text-align: left;
    }
}
.up-descriptiontitle{
    width: 100%;
    float: left;
    padding: 0 0 10px;
}
.up-hashtag{
    width: 100%;
    float: left;
}
.up-hashtag span{
    float: left;
    padding: 0 8px;
    color: #fff;
    font-size: 12px;
    line-height: 28px;
    border-radius: 4px;
    margin: 0 3px;
    background: #00A5D7;
}
.up-eventaddeduserdetail{
    width: calc(100% - 340px);
}
.up-imprteventmodattextholder p{word-break: break-word;}
.up-evenetdetailimg{
    max-width: 80%;
    margin: 0 auto 30px;
    overflow: hidden;
    width: 100%;
    // height: 240px;
    border-radius: 12px;
    img{
        width: 100%;
        height: auto;
        display: block;
        object-fit: cover;
    }
}
.up-ticketgloballinkholder{
    width: 100%;
    float: left;
    margin: 30px 0 0;
    padding: 0;
    list-style: none;
    li{
        float: left;
        width: 30%;
        padding: 0 40px 0 0;
        list-style-type: none;
        &:last-child{padding: 0;}
        figure{
            width: 30px;
            float: left;
            margin: 0 8px 0 0;
            img{
                width: 100%;
                height: auto;
                display: block;
            }
        }
        span{
            width: calc(100% - 38px);
            float: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
.up-flexcenter{
    display: flex;
    justify-content: center;
}
.up-formverifyemail{
    .form-group{
        // width: 25% !important;
        // float: left;
        margin: 16px 0 25px !important;
        text-align: center;
        .up-btn{
            float: none !important;
            display: inline-block !important;
        }
        // input{
        //     width: 60px !important;
        //     height: 60px !important;
        //     text-align: center;
        //     padding: 8px !important;
        // }
    }
}
.up-verifyemailpage{
    text-align: center
}
.up-eventmodalwidget .up-eventhead{
    display: flex;
    justify-content: center;
    align-items: center;
}
.up-zindexzero{z-index: 0;}
.pl-dropdownmembersrole{
    float: right;
}
.pl-btndropdownmemeber{
    &:after{
        display: none !important;
    }
    &:focus{
        box-shadow: none !important;
    }
}
.up-boxpaddingnone{
    padding: 0;
    box-shadow: none;
}
.up-dropdownmembersmenu{
    padding: 6px 0 !important;
    border: 0 !important;
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
    button{
        display: block;
        width: 100%;
        text-align: left;
        padding: 6px 12px;
        border-bottom: 1px solid #c3c3c3;
        &:first-child{
            // padding: 12px 12px 6px; 
        }
        &:last-child{
            // padding: 6px 12px 12px;
            border: 0;
        }
    }
}
.up-getallgrouphome{
    // display: block;
    padding: 0 0 12px;
}
.up-usergroupmodalheadforhom{
    width: 100%;
    float: left;
}
.up-usergroupmodalheadforhom .up-getallgrouphome:nth-child(2),
.up-usergroupmodalheadforhom .up-getallgrouphome:nth-child(3){
    float: right;
    padding: 0 0 0 12px;
}
.up-sharepostdropdownarea .up-dropdownsharepost{
    width: 180px !important;
    border-radius: 6px !important;
}
.up-sahredropdowniconopen{
    margin: 5px 0 0 0;  
}
.up-checkboxadmin,
.up-checkboxmemeber{
    float: right;
    width: 55px;
    margin: 24px 0 0 12px;
}
.up-adduserfieldbtnholder{
    width: 100%;
    float: left;
    margin: 0 0 30px;
    .up-btn{
        margin: 0 !important;
        border-radius: 12px !important;
    }
}
.up-adduserfieldmain{
    width: 100%;
    float: left;
    .up-saveeditbtns{
        position: static;
        transform: none;
        padding: 5px 0;
    }
    .up-adduserfieldinputholder{
        width: 100%;
        float: left;
        input{
            width: 100%;
            padding: 10px 40px 10px 20px;
        }
    }
}
.up-assignuserfieldinput{
    width: 100% !important;
    label{width: 100%;}
    .up-inputwithicon{
        width: calc(100% - 100px);
        margin: 0 15px 0 0;
        input{width: 100%;}
    }
    .up-btn{float: left;}
}
.up-editprofileinputs .form-group.up-assignuserfieldinput{
    width: 100% !important;
    margin: 0 !important;
    padding: 0 16px 16px;
}
.up-assignuserfieldbox{
    width: 300px;
    float: none;
    margin: 0 auto;
}
.up-modaluserreporttabs{ 
    .modal-dialog{
        max-width: 850px;
        .modal-content{
            min-height: 500px;
            .ct-inputcenter{
                max-width: 380px;
                float: none; margin: 0 auto 25px;
            }
        }
    }
}
.up-userassignstabsnav{
    text-align: center;
}
.up-reportmodalformcontent{padding: 30px 0 0;}
.up-assignusergroupmodalcontent{
    padding: 30px 0 0;
    .up-allgroups{
        h4{
            text-align: center;
        }
    }
}
.up-assignuserfieldbox{
    max-width: 500px;
    width: 100%;
    margin: 30px auto 0;
}
.up-filespage{
    width: 100%;
    float: left;
    min-height: 100vh;
}
.up-shortcutgrid{
    clear: both;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
}
.up-shortcutgriditem{
    width: 100%;
    float: left;
}
.up-shortcutcontent{
    float: left;
    width: calc(100% - 20px);
    figure{
        margin: 0;
        width: 100%;
        float: left;
        height: 160px;
        position: relative;
        & > img{
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;
        }
        figcaption{
            right: -30px;
            bottom: 10px;
            position: absolute;
            span{
                width: 60px;
                float: left;
                height: 60px;
                padding: 4px;
                background: #fff;
                border-radius: 50%;
                img{
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }
        }
    }
}
.up-shortcutcardtitle{
    width: 100%;
    float: left;
    padding: 10px 0 0;
    h4{
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        text-align: center;
    }
}
.up-dropdowncommentpopup{
    padding: 23px 0 0 !important;
    // position: absolute !important;
    button{
        color: #000 !important; 
        &:hover,
        &:focus{color: #000 !important;}
    }
    .up-themedropdownmenu{
        top: 75px !important;
        left: - 53px !important;
    }
}
.pl-toplevelgrouptext{
    color: #707070;
    cursor: pointer;
    display: block;
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 16px;
}
.handle-line-breaks{
    white-space: pre-line;
    word-break: break-all;
}
.cursor{
    cursor: pointer;
}
.full-width{
    width: 100% !important;
}
.up-checkboxpopup{
    width: 100%;
    float: left;
    padding: 0 0 0 40px;
    label{
        padding: 0 0 0 10px;
    }
    input{border-radius: 50%;}
}
.up-tableheading{
    thead{
        tr{
            th{
                font-weight: 700;
                text-align: center;
                &:first-child{text-align: left;}
            }
        }
    }
}
.up-iconstyle{
    padding: 0;
    margin: -22px 0 0;
}
.up-tableimagecenter{
    display: flex !important;
}
.up-eventflexparent{
    .up-themedropdown{
        padding: 6px 0;
    }
}
.up-btnlinkedin{
    background: #0C63BC;
}
.up-modalheightset{
    overflow: auto;
    height: calc(100vh - 150px);
}
.up-selectsearch{
    position: absolute;
    right: 0;
    top: 0;
    width: 120px;
    .up-selecttheme{
        padding: 0 0 0 10px;
        border-radius: 0 30px 30px 0;
    }
}
.up-btn-event{
    overflow: inherit !important;
    position: relative;
    padding: 0;
    color: #256A8A;
    div{
        position: absolute;
        right: 2px;
        background: #f00; 
        color: #fff;
        width: 20px;
        top: 0;
        height: 20px;
        border-radius: 50%;
    }
}
.up-notificationdropdown{
    .dropdown-toggle{
        position: relative;
        span{
            position: absolute;
            right: -5px;
            font-size: 12px;
            background: #f00; 
            color: #fff !important;
            width: 20px;
            top: -5px;
            height: 20px;
            border-radius: 50%;
        }
    }
}
.up-inputwithselect{
    & > input{
        height: 40px !important;
        padding: 10px 106px 10px 50px !important;
    }
}
// .up-testclass{
//     width: 100%;
//     float: left;
// }
.up-testclass__highlighter{
    padding: 9px 100px 9px 9px !important;
    // position: absolute !important;
    // width: auto !important;
    // max-width: calc(100% - 240px);
    // z-index: 1;
    // left: 20px;
    // top: 50%;
    // -moz-transform: translateY(-50%);
    // -webkit-transform: translateY(-50%);
    // -o-transform: translateY(-50%);
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);
    // span{
    //     visibility: visible !important;
    //     color: #414141 !important;
    // }
}
.up-testclasscontent{
    // font-weight: 700 !important;
    color: #414141 !important;
    visibility: hidden;
    display: none !important;
}
.up-testclassvtwo__suggestions,
.up-testclass__suggestions{
    top: auto !important;
    bottom: 20px ;
    ul{
        min-height: 30px;
        max-height: 300px;
        overflow: auto;
        &::-webkit-scrollbar{display: none;}
    }
}
.up-formorgnizationadmin{
    .form-group{
        label{
            margin: 0 0 10px !important;
        }
    }
}
.up-modaloverflowremove{
    .modal-dialog{
        // overflow: initial !important;
        max-width: 380px !important;
        .modal-content{
            padding: 0 0 180px;
        }
    }
}
.up-verifedbadge{
    width: 22px;
    padding: 0;
    display: inline-block !important;
    height: 18px;
    margin: 0;
    span{padding: 0}
}
#react-select-1-listbox,
#react-select-2-listbox,
#react-select-3-listbox,
#react-select-4-listbox,
#react-select-5-listbox{
    height: 300px !important;
    // overflow: hidden;
}
.up-btncontentholder{
    width: 100%;
    float: left;
    text-align: center;
    padding: 0 0 16px;
}
.ct-widthfull{
    fieldset{
        clear:inherit;
        margin: 0;
        .form-group{
            width: 100%;
            input{
                width: 100%;
                float: left;
            }
        }
    }
}
.up-modalinnerscroll{
    margin: 0;
    overflow: auto;
    height: calc(100vh - 210px);
}
.up-modalusergrouparea{
    .modal-dialog{
        max-width: 810px;
    }
}
.up-pollclosedtext{
    padding: 10px 0 0;
}
.up-markasreadpin{
    top: 0;
    right: 20px;
    width: 17px;
    z-index: 9;
    cursor: pointer;
    position: absolute;
    transform: rotate(-44deg);
}
.up-publicdatearea{
    width: 100%;
    float: left;
    span{
        width: auto !important;
        float: left !important;
        padding: 0 2px ;
        span{
            padding: 0 10px 0 0;
        }
    }
}
.up-defaultmemberarea{
    h3{
        float: left;
    }
    span{
        float: left;
        color: #fff;
        font-size: 14px;
        line-height: 10px;
        background: #ffa608;
        padding: 5px;
        margin: 3px 0 0 8px;
    }
}
.up-postfileuploaded{
    width: auto;
    float: left;
    border-radius: 6px;
    padding: 8px 8px 8px 4px;
    background: rgba(13,110,243,0.2);
    em{
        float: left;
        // width: 22px;
        font-size: 22px;
        margin: 0 4px 0 0;
        line-height: 16px;
        i{
            line-height: 18px;
        }
    }
    span{
        float: left;
        font-size: 16px;
        line-height: 16px;
        width: calc(100% - 26px);
    }
}
.up-fileiconparent{
    width: 100%;
    float: left;
    padding: 4px 0;
}
.up-labelinlineparent{
    a,
    label{
        display: inline-block;
    }
}
.up-dropdownstyle{
    .up-themedropdown{
        .up-themedropdownmenu{
            min-height: 240px !important;
        }
    }
}
.up-multiselectholder{
    .optionListContainer{
        bottom: 60px !important;
    }
}
.up-latesticon{
    left: 0 !important;
    width: 44px !important;
    height: 44px !important;
    border-radius: 8px !important;
    img{
        width: 24px !important;
    }
}
.up-dflex{
    display: flex;
    gap: 16px;
    align-items: center;
    span{width: 40px;}
}
.up-fontnormal{
    font-weight: 300;
    width: auto !important;
    padding: 0 5px 0 0 !important;
}
.up-iconreplymessage{
    // display: none;
    cursor: pointer;
    position: relative;
    svg{
        width: 20px;
        fill: #5ab5df;
    }
}
.up-replyhover{
    width: 124px;
    padding: 4px;
    right: 0;
    top: -30px;
    display: none;
    background: #fff;
    border-radius: 6px;
    list-style: none;
    position: absolute;
    -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    li{
        float: left;
        text-align: center;
        list-style-type: none;
        width: calc(100% / 4);   
        span{
            width: 26px;
            text-align: center;
            height: 26px;
            border-radius: 50%;
            padding: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.disabled{
                background: rgba(0, 0, 0, 0.2);
            }
            svg{
                width: 20px;
                fill: #5ab5df;
            }
        } 
    }
}
.up-replyinfoicon{
    padding: 0;
    width: 56px;
    display: none;
    list-style: none;
    li{
        padding: 0 4px;
        float: left;
        line-height: inherit;   
        list-style-type: none;
    }
}
.up-chatfilesarea,
.up-messagecontentholder{
    position: relative;
    .up-messagereactionhover:hover ~ .up-replyinfoicon,
    .up-replyinfoicon:hover,
    .up-replyhover:hover,
    .up-messagereactionhover:hover .up-replyhover{display: block;}
    .up-replyinfoicon{
        float: left;
        margin: 0;
        padding: 0;
    }
}
.up-recievermessage .up-messagecontentholder{
    .up-replyinfoicon{
        float: right;
        margin: 0;
        padding: 0;
    }
    .up-replyhover{
        left: 0;
        right: auto;
    }
}
.up-messagereply{
    width: 100%;
    // float: left;
    display: flex;
    align-items: center;
    padding: 6px;
    margin: 0 0 4px;
    background: #f7fafd;
    border-radius: 6px;
    span{
        display: block;
        padding: 0 10px 0 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
.up-replymessageholder{
    width: 100%;
    display: flex;
    position: relative;
    padding: 6px 40px 6px 20px;
    border-radius: 6px;
    margin: 0 0 10px;
    align-items: center;
    background: rgba(90, 181, 223, 0.16);
    justify-content: space-between;
    span{
        color: var(--Malibu);
    }
}
.up-addattacmentbutton,
.up-btnsendchat{
    top: auto;
    bottom: 10px;
    transform: inherit;
}
.up-addattacmentbutton{
    svg{
    width: 12px;
    fill: #fff;
    }
}
.up-reactiononmessgae{
    width: 100%;
    float: left;
    position: relative;
    margin: -14px 0 10px 0;
    span{
        padding: 4px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.5 );
        -webkit-box-shadow: 0 0 25px 0 rgba(0,0,0,0.25);
        box-shadow: 0 0 25px 0 rgba(0,0,0,0.25);        
    }
}
.up-recievermessage{
    .up-reactiononmessgae  {
        float: right;
        span{
            float: right;
        }
    }
}
.ct-messagedataholder{
    width: auto;
    padding: 10px;
    max-width: calc(100% - 40%);
    margin: 0;
    position: relative;
    border-radius: 12px;
    background: rgba(90, 181, 223, 0.16);
    &:before {
        top: 16px;
        width: 0;
        height: 0;
        right: 100%;
        content: '';
        position: absolute;
        // -webkit-transform: rotate(5deg) translateY(-50%);
        // -moz-transform: rotate(5deg) translateY(-50%);
        // -ms-transform: rotate(5deg) translateY(-50%);
        // -o-transform: rotate(5deg) translateY(-50%);
        // transform: rotate(5deg) translateY(-50%);
        border-top: 5px solid transparent;
        border-right: 15px solid rgba(90, 181, 223, 0.16);
        border-bottom: 5px solid transparent;
    }
}
.up-btninfoicon{
    color: #5ab5df;
    padding: 4px 0 0;
}
.up-recievermessage{
    .up-btninfoicon{
        float: right;
    }
    .ct-messagedataholder{
        float: right;
        &:before {
            left: 100%;
            right: auto;
            border-right: 0;
            border-top: 5px solid transparent;
            border-left: 15px solid rgba(90, 181, 223, 0.16);
            border-bottom: 5px solid transparent;
            -webkit-transform: rotate(5deg) translateY(-50%);
            -moz-transform: rotate(-5deg) translateY(-50%);
            -ms-transform: rotate(-5deg) translateY(-50%);
            -o-transform: rotate(-5deg) translateY(-50%);
            transform: rotate(-5deg) translateY(-50%);
        }
    }
    .up-messagereplyinfoiconsholder{
        justify-content: end;
        flex-direction: row-reverse;
    }
}
.up-sendermessage{
    .ct-messagedataholder{
        float: left;
    }
    .up-btninfoicon{
        float: left;
    }
    .up-messagereplyinfoiconsholder{
        justify-content: start;
    }
}

.up-chatfile{
    .up-filesdataholder{
        padding: 0;
        width: 100%;
        max-width: 100%;
        position: static;
        background: no-repeat;
    }
    &:hover .up-replyinfoicon,
    &:hover .up-replyhover{
        display: block;
    }
}
.up-messagereplyinfoiconsholder{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 10px;
}
.up-chatfilesarea{
    .up-reactioshowfiles{
        margin: -100px 0 10px 0;
    }
}
.up-fileswidthcalc{
    width: auto;
    position: relative;
    max-width: calc(100% - 70px);
}
.carousel-containor{
    border-radius: 12px;
    margin-bottom: 12px;
    .carousel-img{
        object-fit: cover;
        height: 400px;
        border-radius: 12px;
        display: block;
        width: 100%;
    }
}
.up-countmessage{
    font-size: 10px;
    background: #f00;
    color: #fff !important;
    width: 14px;
    margin: 0 10px 0 0px;
    float: right;
    text-align: center;
    height: 14px;
    line-height: 14px;
    border-radius: 50%;
}
.up-sldierhight{
    > div:first-child{
        height: 400px !important;
    }
}
.up-recievermessage{
    .up-sidechatreplyhover{
        left: -30px !important;
    }
}
.up-replayimageholder{
    flex-direction: column;
    align-items: start;
    // .up-btncrossreply{
    //     width: 100%;
    //     float: right;
    //     text-align: right;
    // }
}
.up-btncrossreply{
    position: absolute;
    top: 50%;
    right: 13px;
    color: #a39090;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    i{
        color: #a39090;
    }
}
.up-mediareplaygrid{
    display: flex;
    gap:8px;
    figure{
        height: 50px !important;
        width: 50px !important;
    }
}
.up-replymessagecrossicon{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 10px 0 0;
        overflow: hidden;
    }
}
.up-addfileicononchat{
    right: 132px !important;
    svg{
        width: 12px;
        fill: #fff;
    }
}
.up-fileiconwithname{
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: auto;
        padding: 0 10px 0 0;
        overflow: hidden;
        max-width: 380px;
        font-size: 14px;    
    }
}
.up-bottomchat .up-chatfilesholder .up-chatfile .up-chatfileimage .up-fileiconwithname span{
    max-width: 100px;
}
.up-textpluscount{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    .up-countmessage {
        font-size: 14px;
        background: #f00;
        color: #fff !important;
        width: 20px;
        margin: 0 10px 0 0px;
        float: right;
        text-align: center;
        height: 20px;
        line-height: 20px;
    }
}
.up-btnjoinaccept{
    &:hover span{color: #fff;}
    & > span{
        display: flex !important;
        height: 40px;
    }
}
.up-mainsearchholder > div > div{
    padding: 0 !important;
    & > div > div{
        padding: 0 10px !important;
    }
}
.up-async-select > div > div > div:focus-visible{
    border: 0 !important;
    outline: none !important;
    box-shadow:none !important;
}
.up-mainsearchholder > div:first-child,
.css-nfioa4-control{
    border-radius: 30px !important;
    border-color: hsl(0, 0%, 70%) !important;
}
.react-datepicker-wrapper{
    width: 100%;
}
.css-1jqq78o-placeholder{
    position: absolute;
    padding: 0 0 0 50px;
    font-size: 12px;
    grid-area: auto !important;
}
.up-eventsdropdownmenu{
    inset: auto !important;
    right: -74px !important;
    top: 50px !important;
}
.up-calendrrefreshicon{
    position: absolute;
    top: 69px;
    right: 20px;
    cursor: pointer;
    svg{
        width: 18px;
    }
}   

.up-mainsearchholder > div > div:last-child{
    padding: 0 !important;
    div{
        overflow: hidden !important;
        // padding: 14px;
    }
}
.up-eventpageselectissue > div > div:first-child{
    padding: 0 4px !important;
    div{
        overflow: hidden !important;
    }
}
// padding: 14px;
#react-select-1-placeholder,
#react-select-2-placeholder,
#react-select-3-placeholder,
#react-select-4-placeholder,
#react-select-6-placeholder,
#react-select-7-placeholder,
#react-select-8-placeholder,
#react-select-10-placeholder,
#react-select-11-placeholder,
#react-select-12-placeholder,
#react-select-9-placeholder,
#react-select-5-placeholder{
    padding: 0 0 0 50px;
    font-size: 12px;
}
.up-searchuserslist{
    display: flex;
    text-align: left;
}
.up-userlisttextleft{
    text-align: left;
}
.up-selectdeleteallholder{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rounded-image {
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover;  /* Ensures the image covers the area */
}
.up-btncircel{
    padding: 0;
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 40px;
    border-radius: 50%;
    svg{
        width: 22px;
        fill: #fff; 
    }
    span{
        position: absolute;
        left: -40px;
        bottom: -25px;
        font-size: 14px;
        width: 150px;
        background: #5ab5df;
        line-height: 20px;
        border-radius: 12px;
        opacity: 0;
        visibility: hidden;
    }
    &:after{
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        left: 60px;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        border-bottom: 100px solid #5ab5df;
    }
    &:hover{
        span{
            opacity: 1;
            visibility: visible;
            
        }
    }
}
.up-memberprofilebottomheight{
    min-height: 90px;
    .up-memberprofilepaddinglow{
        padding: 24px 0;
    }
}
.up-btntranslate{
    // float: left;
    font-size: 14px;
    line-height: 16px;
    // text-decoration: underline;
    padding: 20px 0 0;
    font-weight: 300;
}
// .up-posttranslatecontent{float: left;}
.up-membersiconholder{
    float: right;
    padding: 0 12px 0 0;
    button{
        margin: 0 2px !important;
        padding: 0;
        width: 40px !important;
        height: 40px;
        border-radius: 50% !important;
        line-height: 40px !important;
        float: left;
    }
}
.up-membernameuserarea{
    width: calc(100% - 250px);
    float: left;
}
.up-tableusername{
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.up-usertablescrollholder{
    overflow: auto;
    table{
        min-width: 1300px;
        overflow: auto;
    }
}
.up-usertableiconsholser{
    width: 186px;
    padding: 7px 0 7px 16px;
    button{
        width: 30px !important;
        height: 30px !important;
        margin: 0 2px !important;
        line-height: 30px !important;
        svg{
            width: 17px;
        }
    }
}
.up-togglealignflex{
    display: flex;
}


@import 'responsive.scss';