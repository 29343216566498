@media (max-width: 1450px){
    .up-logincontentarea{padding: 40px;}
    .up-loginformholder {
        right: 50px;
        width: 420px;
        padding: 40px 30px 40px;
    }
}
@media (max-width: 1366px){
    
    .up-ownorganisationprofilepage{
        .up-profilebannercontent{padding: 25px;}
        .up-profilenameimage{left: 25px;}
        .up-organisationprofileimg{
            width: 80px;
            height: 80px;
            margin: 0 10px 0 0;
        }
        .up-organisationname{
            h3 {
                font-size: 22px;
                line-height: 24px;
            }
            span{
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
    .up-btneditogranisation{font-size: 12px;}
    .up-profiletabs{
        font-size: 14px;
        .nav-link{padding: 0 10px;}
    }
    .up-eventcolone{
        width: calc(100% - 420px);
    }
    .up-eventcoltwo{
        width: 390px;
    }
    .up-statsgrid{
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
    .up-stat{padding: 12px;}
    .up-stat .up-staticon {
        width: 50px;
        height: 50px;
        margin: 0 12px 0 0;
    }
    .up-stat .up-statcontent {
        width: calc(100% - 62px);
    }
    .up-stat .up-statcontent ul li span,
    .up-stat .up-statcontent ul li a{font-size: 14px;}
    .up-stat .up-statcontent ul li h3{font-size: 22px;}
    .up-dashboradcontent{
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
    .up-modaluserreporttabs .modal-dialog {
        max-width: 600px;
    }
}
@media (max-width: 1199px){
    .up-globalsearch{
        max-width: 300px;
        min-width: 300px;
    }
    .up-messgnotificationicons {padding: 11px 15px 11px 0;}
    .up-eventspage{
        width: 100%;
        float: left;
        .up-event{
            .up-eventhead{
                .up-eventnameimgdate{width: 100%;}
                .up-eventbuttons{
                    width: 100%;
                    margin: 15px 0 0;
                }
            }
        }
    }
    .up-mainchatpage{
        .up-chatsidebararea{width: 280px;}
        .up-chatmessagearea {width: calc(100% - 280px);}
    }
    .up-userfilterholder .up-formtheme{
        width: 100%;
        margin: 12px 0;
    }
}
@media (max-width: 1024px){
    .up-wrapper {
        overflow: hidden;
        padding: 110px 15px 30px 15px;
    }
    .up-wrappertwo{
        padding: 100px 15px 30px 15px;
    }
    .up-sidebardashboard .list-item a em{left: 50px;}
    .up-headerdashboard .up-menuiconholder,
    .up-sidebardashboard .up-menuiconholder{display: block;}
    .up-sidebarwrapper{left: -100%;}
    .up-openclose-sidebar-menu .navigation,
    .up-openclose-sidebar-menu .up-sidebarwrapper {left: 0;}
    .up-onlinememberholder{
        right: -150%;
        position: relative;
        height: calc(100vh - 58px);
    }
    .up-opensidebarchat{
        .up-onlinememberholder{left: 0;}
    }
    .up-loginarea{
        overflow: auto;
        padding: 0 0 30px;
    }
    .up-logincontentarea{
        width: 100%;
        float: left;
        height: auto;
        padding: 30px;
        text-align: center;
    }
    .up-logincontentarea h3 {
        font-size: 24px;
        line-height: 30px;
    }
    .up-logincontentarea .up-description{
        max-width: 100%;
        text-align: center;
    }
    .up-loginformholder {
        right: auto;
        width: 100%;
        height: auto;
        margin: 0 auto;
        max-width: 420px;
        min-height: auto;
        position: relative;
        padding: 30px 15px;
        .up-logo{width: 100px;}
    }
    .up-formHead{
        margin: 20px 0 0;
        h1{
            font-size: 24px;
            line-height: 27px;
        }
    }
    .up-loginformcontentholder{
        min-height: auto;
        overflow: hidden;
    }
    .up-signuparea{
        .up-loginformholder {padding: 30px 15px;}
    }
    .up-forgotpasswordpage{padding: 40px 0;}
    .up-signupsteptwo{
        overflow: auto;
        padding: 0 0 30px;
        .up-logincontentarea{
            clear: both;
            float: none;
            max-width: 500px;
            margin: 0 auto 30px;
            .up-singupsteptwocontent{
                h3{
                    font-size: 18px;
                    line-height: 24px;
                    span{
                        font-size: 22px;
                        line-height: 25px;
                    }
                }
            }
        }
        .up-loginformholder{
            clear: both;
            padding: 30px 15px;
        }
    }
    .up-bulletpoints{text-align: left;}
    .up-eventsholder,
    .up-eventcalendarholder{width: 100%;}
    .up-myprofilepage{
        .up-bannerbottomarea{
            padding: 0 20px 0 0;
            .up-profilenameimage{
                padding: 0;
                width: 100%;
                display: block;
            }
            .up-btn{
                float: left;
                margin: 44px 0 44px 25px;
            }
            .up-organisationname{
                width: 100%;
                float: left;
                padding: 20px 0 0;
            }
        }
    }
    .up-myprofilepage{
        .up-twocolumns{
            .up-sidebar{
                width: 100%;
                margin: 0 0 30px;
            }
            .up-profilefollowingarea{width: 100%;}
        }
    }
}
@media (max-width: 1000px){
    .up-wrapper {padding: 85px 15px 30px 15px;}
    .up-header{width: calc(100% - 50px);}
    .up-headerdashboard{
        width: 100%;
    }
    .up-headercontent{
        padding: 10px 0;
        .up-logo {
            width: 100px;
            padding: 3px 0;
        }
    }
    .up-headerrightarea {
        padding: 0 15px 0 0;
        .up-userimage{
            width: 35px;
            height: 35px;
            margin: 0 10px 0 0;
        }
    }
    .up-messgnotificationicons {padding: 2px 15px 2px 0;}
    .up-userprofilecontent {
        padding: 10px 18px 10px 0;
        h3{
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .up-menuiconholder {
        width: 50px;
        height: 24px;
        line-height: 30px;
    }
    .up-chatwrapper{width: 50px;}
    .up-btnchat {
        width: 50px;
        height: 58px;
        font-size: 18px;
    }
    .up-globalsearch{display: none;}
    .up-postsholder{
        width: 100%;
        margin: 0 0 30px;
    }
    .up-sidebar{width: 100%;}
    .up-postnameuserimage{display: block;}
    .up-postheadcontent{
        width: 100%;
        float: left;
        margin: 15px 0 0;
    }
    .up-twoimages{
        .up-postimage{width: calc(100% - 10px);}
    }
    .up-mainchatpage{
        .up-chatfile{width: calc(25% - 4px);}
    }
    .up-editogranisationtabs{
        .up-formeditprofile{
            .up-editprofileinputs{
                .form-group{width: calc(100% - 44px) !important;}
            }
        }
    }
    .up-organiserprofiletabarea{
        .tab-pane{
            .up-themeboxhead{
                h3{
                    font-size: 20px;
                    line-height: 23px;
                }
            }
        }
    }
    .up-searchgroupcreate{
        width: 100%;
        float: left;
        margin: 20px 0 0;
        .up-formallmembers{width: calc(100% - 156px);}
    }
    .up-mainchatpage{
        .up-chatsidebararea{
            width: 100%;
            height: auto;
            margin: 0 0 20px;
            .up-chatusers{height: 200px;}
        }
        .up-chatmessagearea {width: 100%;}
    }
    .up-eventcolone{
        width: 100%;
        margin: 0 0 30px;
    }
    .up-eventcoltwo{
        width: 100%;
    }
    .up-userfilterholder .up-formtheme .up-select, .up-userfilterholder .up-formtheme .form-group {
        width: 160px;
    }
    .up-usertableholder{
        overflow: auto;
        table{
            min-width: 800px;
            overflow: auto;
        }
    }
    .up-eventtwocol{
        display: flex;
        flex-direction: column-reverse;
    }
}
@media (max-width: 768px){
    .up-responsivetable thead{display: none;}
	.up-responsivetable tbody tr td{
        width: 100%;
        float: left;
		display: flex;
        min-height: 65px;
		position: relative;
        align-items: center;
		padding: 5px 20px 5px calc(28% + 50px) !important;
        &:first-child,
        &:last-child{padding: 5px 20px 5px calc(28% + 50px) !important;}
	}
	.up-responsivetable tbody tr td:before{
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
        display: flex;
		font-size: 14px;
		color: #6b6b6b;
		padding: 5px 20px;
		position: absolute;
        align-items: center;
		line-height: inherit;
		content: attr(data-title);
	}
    .up-formsearchfiles{
        fieldset{
            padding: 0;
            .form-group {
                margin: 5px;
                width: calc(100% - 10px);
            }
            .up-btn{
                width: 100%;
                float: left;
                margin: 5px 0 0;
                position: static;
            }
        }
    }
    .up-profiletableresponsive{
        tbody{
            tr{
                td{
                    padding: 5px 20px 5px 15px !important;
                    &:before{display: none;}
                    &:last-child,
                    &:first-child{padding: 5px 20px 5px 15px !important}
                    .up-fileiconname{display: flex !important;}
                    .up-organisermetadata{
                        padding: 0;
                        width: auto;
                        display: block;
                        li{
                            width: 100%;
                            padding: 10px 0;
                            text-align: left;
                            &:before{display: none;}
                        }
                    }
                }
            }
        }
    }
    .up-myprofilepage{
        .up-formeditprofile{
            .up-editprofileinputs{
                .form-group{width: calc(100% - 44px) !important;}
            }
        }
    }
    .up-alleventsholder{
        .up-eventnameimgdate{width: 100%;}
        .up-eventbuttons{
            width: 100%;
            float: left;
            margin: 20px 0 0;
            .up-themedropdown{float: right;}
            li{
                .up-btn{padding: 0 20px;}
            }
        }
    }
    .up-btneditogranisation{
        top: -336px;
        z-index: 5;
    }
    .up-inputresponsive{
        margin: 0 !important;
        .form-group{
            width: 100% !important;
            margin: 0 0 16px !important;
        }
    }
}
@media (max-width: 640px){
    .up-wrapper {padding: 140px 15px 30px 15px;}
    .up-wrappertwo{padding: 85px 15px 30px 15px;}
    .up-headerrightarea {
        width: 100%;
        padding: 15px 15px 0 5px;
        .up-userdropdown{float: right;}
    }
    .up-menuiconholder{float: right;}
    .up-headercontent{
        .up-logo {margin: 0 0 0 15px;}
    }
    .up-forgotpasswordpage {
        .up-loginformholder{
            .up-loginformcontentholder{
                .up-formHead{
                    h1 {
                        font-size: 16px;
                        margin: 0 0 30px;
                    }
                }
            }
        }
    }
    .up-formsignup{
        fieldset{
            .form-group{
                .up-btn{
                    width: 100%;
                    margin: 15px 0 0;
                }
            }
        }
    }
    .up-followorgaizerspage{
        padding: 30px 0;
        .up-logo {
            width: 100px;
            margin: 0 auto 30px;
        }
    }
    .up-followerhead{
        h2 {
            font-size: 20px;
            margin: 0 0 30px;
            line-height: 23px;
        }
    }
    .up-followorganizers{
        .up-btn {
            max-width: none;
            min-width: auto;
        }
    }
    .up-messgnotificationicons{
        li{
            .up-themedropdownmenu {
                left: 0 !important;
                top: 50px !important;
                right: auto !important;
                &:after{
                    left: 5px;
                    right: auto;
                }
            }
        }
    }
    .up-btnchat {height: 108px;}
    .up-sidebarwrapper{
        top: 108px;
        height: calc(100vh - 108px);
    }
    .up-sidebardashboard{
        top: 0;
        height: 100vh;
    }
    .up-grouptableresposnive{
        tbody{
            tr{
                td{
                    display: block;
                    padding: 5px 20px 0 !important;
                    &:before{
                        padding: 0;
                        width: 100%;
                        float: left;
                        position: static;
                    }
                    &:first-child,
                    &:last-child{padding: 5px 20px 0 !important;}
                    .up-btn{
                        width: 100%;
                        padding: 0 5px;
                        i{display: none;}
                    }
                    .up-fileiconname{
                        display: block;
                        .up-fileicon{margin: 0 0 15px;}
                        h4{
                            width: 100%;
                            float: left;
                        }
                    }
                }
            }
        }
    }
    .up-searchgroupcreate{
        width: 100%;
        float: left;
        margin: 20px 0 0;
        .up-formallmembers{width: 100%;}
        .up-btn{
            width: 100%;
            float: left;
            margin: 20px 0 0;
        }
    }
    .up-dashboarddata,
    .up-eventholder{
        padding: 12px;
    }
    .up-eventsupdatesimageholder figure{
        height: 160px;
    }
    .up-dashboarddata .up-dashboardsectiontitle h3{
        font-size: 14px;
    }
    .up-userfilterholder .up-formtheme .form-group,
    .up-userfilterholder .up-formtheme button{
        width: calc(50% - 6px);
        margin: 0 6px 12px 0;
        padding: 0;
    }
    .up-userfilterholder .up-formtheme .up-filterbtn,
    .up-userfilterholder .up-formtheme .up-select{
        width: calc(50% - 6px);
        margin: 0 0 12px 6px;
    }
    .up-userfilterholder .up-formtheme .up-filterbtn button{
        width: 100%;
        margin: 0;
    }
    .up-userfilterholder .up-userspagetitle h2{
        font-size: 22px;
        line-height: 25px;
    }
    .up-statsgrid,
    .up-dashboradcontent{
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}